import Cookies from "js-cookie";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import constants, { alertError, alertInfo } from "../constants";
import { stringSimilarity } from "string-similarity-js";
import moment from "moment";

export function getProgressColor(progress) {
  // Convert progress to a value between 0 and 1
  const normalizedProgress = progress / 100;

  // Define color ranges
  const startColor = [255, 0, 0]; // Red for lower progress
  const endColor = [0, 255, 0]; // Green for higher progress

  // Interpolate between startColor and endColor based on progress
  const color = startColor.map((channel, index) => {
    return Math.round(
      channel + (endColor[index] - channel) * normalizedProgress
    );
  });

  // Construct color string in RGB format
  const rgbColor = `rgb(${color.join(",")})`;

  return rgbColor;
}

export function getProgressColorCombo(value, goodValue) {
  // Normalize value to a range between 0 and 1
  const normalizedProgress = Math.min(Math.max(value / goodValue, 0), 1);

  // Define color stops (red -> yellow -> green)
  const startColor = [255, 0, 0]; // Red (low progress)
  const middleColor = [255, 255, 0]; // Yellow (mid progress)
  const endColor = [0, 255, 0]; // Green (high progress)

  // Interpolate colors based on progress
  let bgColor;
  if (normalizedProgress <= 0.5) {
    // Interpolate between red and yellow
    const progress = normalizedProgress * 2; // Scale to 0-1
    bgColor = startColor.map((channel, index) =>
      Math.round(channel + (middleColor[index] - channel) * progress)
    );
  } else {
    // Interpolate between yellow and green
    const progress = (normalizedProgress - 0.5) * 2; // Scale to 0-1
    bgColor = middleColor.map((channel, index) =>
      Math.round(channel + (endColor[index] - channel) * progress)
    );
  }

  // Construct background color string in RGB format
  const bgColorString = `rgb(${bgColor.join(",")})`;

  // Calculate brightness to determine a suitable text color
  const brightness =
    (bgColor[0] * 0.299 + bgColor[1] * 0.587 + bgColor[2] * 0.114) / 255;

  // Choose white text for dark backgrounds and black text for light backgrounds
  const textColor = brightness > 0.5 ? "black" : "white";

  return {
    bg: bgColorString,
    text: textColor,
  };
}

export function calculateDaysWithoutSundays(start, end) {
  let current = moment(start);
  const lastDate = moment(end);
  let daysWithoutSundays = 0;

  // Loop through the date range
  while (current.isSameOrBefore(lastDate)) {
    if (current.day() !== 0) {
      // Add day if not Sunday (0 represents Sunday in Moment.js)
      daysWithoutSundays++;
    }
    current.add(1, "day"); // Move to the next day
  }

  return daysWithoutSundays;
}

export function confirm(message, yes, no = null) {
  var conf = window.confirm(message);
  if (conf) {
    yes();
  } else if (no) {
    no();
  }
  // confirmAlert({
  //   title: "Confirm to submit",
  //   message,
  //   buttons: [
  //     {
  //       label: "Yes",
  //       onClick: () => {
  //         yes();
  //       },
  //     },
  //     {
  //       label: "No",
  //       onClick: () => {
  //         if (no) {
  //           no();
  //         }
  //       },
  //     },
  //   ],
  // });
}

export function copyToClipboard(text) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Text copied to clipboard:", text);
    })
    .catch((err) => {
      console.error("Failed to copy text to clipboard:", err);
    });
  alertInfo("Copied to clipboard");
}

export function pasteText(onPaste) {
  navigator.clipboard
    .readText()
    .then((text) => {
      console.log("Text read from clipboard:", text);
      // Use the clipboard text as needed, e.g., insert it into an input field
      onPaste(text);
    })
    .catch((err) => {
      console.error("Failed to read text from clipboard:", err);
    });
}

export function truncatedString(str, len = 5) {
  return str.length > len ? `${str.slice(0, len)}...` : str;
}

export function getFavCustomers(onDone) {
  const favsJSON = Cookies.get("favs");

  if (favsJSON) {
    const favs = JSON.parse(favsJSON);
    onDone(favs);
    return favs;
  } else {
    const favs = [];
    onDone(favs);
    return favs;
  }
}

export function setFavCustomer(customer, onDone) {
  const favsJSON = Cookies.get("favs") || "[]";
  const favs = JSON.parse(favsJSON);

  const favIndex = favs.indexOf(customer.id);

  if (favIndex !== -1) {
    favs.splice(favIndex, 1);
  } else {
    favs.push(customer.id);
  }

  // Convert the updated favs array back to JSON and store it in cookies
  Cookies.set("favs", JSON.stringify(favs), { expires: 365 });

  // Update the state with the new favs array
  onDone(favs);
}

export function findSuitableHashTags(
  hashTags,
  stringArray,
  similarityThreshold = 0.5
) {
  const suitableHashTags = [];
  const addedHashTags = new Set();

  // Iterate over each string in the stringArray
  stringArray.forEach((str) => {
    let maxSimilarity = -1;
    let mostSimilarHashTag = null;

    // Iterate over each hash tag to find the most similar one
    hashTags.forEach((hashTag) => {
      // Skip hash tags that have already been added
      if (addedHashTags.has(hashTag.name)) return;

      const similarity = stringSimilarity(str, hashTag.name);

      // Update the most similar hash tag if the similarity is higher
      if (similarity > maxSimilarity) {
        maxSimilarity = similarity;
        mostSimilarHashTag = hashTag.name;
      }
    });

    // Add the most similar hash tag to the suitableHashTags array
    if (maxSimilarity >= similarityThreshold && mostSimilarHashTag) {
      suitableHashTags.push(mostSimilarHashTag);
      addedHashTags.add(mostSimilarHashTag);
    }
  });

  return suitableHashTags;
}

export function extractEnglishWords(sentence) {
  // Regular expression to match English words and words with common special characters
  const englishWordRegex = /[a-zA-Z.'&,!?-]+/g;

  // Extract all English words from the sentence
  const englishWords = sentence.match(englishWordRegex);

  // Join the English words back into a sentence
  const result = englishWords ? englishWords.join(" ") : "";

  // If the result is empty, prompt the user to edit the sentence
  if (!result) {
    const editedSentence = prompt("Correct the word:", sentence);
    return editedSentence ? editedSentence : "";
  }

  return result;
}

export function priceFromList(product, qty) {
  var unit_price = product?.unit_price;
  if (product?.prices?.length > 0) {
    const matchingPrice = product.prices.find(
      (price) => qty >= price.min && qty <= price.max
    );
    if (matchingPrice) {
      unit_price = matchingPrice.unit_price;
    }
  }
  return unit_price;
}

export function deepCopy(object) {
  const deepCopy = JSON.parse(JSON.stringify(object));
  return deepCopy;
}

export function changePassword(user_id) {
  var newPass = window.prompt("Enter new password");

  // Check if the password is provided and at least 9 characters long
  if (!newPass || newPass.length < 9) {
    alertError("Password is too short! It must be at least 9 characters.");
    return;
  }

  // Check if the password contains at least one capital letter
  const hasUpperCase = /[A-Z]/.test(newPass);
  if (!hasUpperCase) {
    alertError("Password must contain at least one uppercase letter.");
    return;
  }

  // Check if the password contains at least one number
  const hasNumber = /[0-9]/.test(newPass);
  if (!hasNumber) {
    alertError("Password must contain at least one number.");
    return;
  }

  // Check if the password contains at least one special character
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPass);
  if (!hasSpecialChar) {
    alertError("Password must contain at least one special character.");
    return;
  }

  fetch(constants.url + "users/" + user_id + "/cpass", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
    body: JSON.stringify({ newPass }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success == false) {
        alertError("Password change failed");
      } else {
        alertInfo("Password Change Success!");
      }
    })
    .catch((error) => {
      alertError("Password change failed");
    });
}

export function greetingsNow() {
  const now = new Date();
  const hours = now.getHours();
  const dayOfWeek = now.toLocaleDateString("en-US", { weekday: "long" });
  const day = now.getDate();
  const month = now.getMonth() + 1; // Fix: Add 1 to make months 1-indexed
  const userMoods = [
    "feeling energetic",
    "having a cozy day",
    "on the go",
    "looking productive",
    "in a relaxed mood",
    "ready to conquer the world",
    "in a cheerful mood",
    "taking it easy",
    "feeling inspired",
    "ready for an adventure",
  ];

  // Greeting based on time of day
  let greeting = "Hello there";
  if (hours < 5) {
    greeting = "Burning the midnight oil, are we?";
  } else if (hours < 12) {
    greeting = "Good morning! Rise and shine!";
  } else if (hours < 17) {
    greeting = "Good afternoon! Keep up the great work!";
  } else {
    greeting = "Good evening! Hope you had a fulfilling day!";
  }

  // Additional messages based on day of the week
  const dayGreetings = {
    Sunday: [
      "Happy Sunday! Time to recharge for the week ahead.",
      "Sunday Funday! Enjoy every moment!",
      "Lazy Sunday vibes are in the air.",
    ],
    Monday: [
      "Happy Monday! Let’s start strong!",
      "Monday motivation: You got this!",
      "A fresh week begins! Make it count!",
    ],
    Tuesday: [
      "Happy Tuesday! Keep the momentum going!",
      "Terrific Tuesday! A great day to be productive.",
      "It's Taco Tuesday! Don’t forget to celebrate.",
    ],
    Wednesday: [
      "Happy Wednesday! We're halfway through!",
      "Midweek magic is in the air!",
      "Wondrous Wednesday! Keep pushing forward!",
    ],
    Thursday: [
      "It's Thursday! Almost there!",
      "Thankful Thursday! Reflect on your week.",
      "Thriving on this Thursday, aren't we?",
    ],
    Friday: [
      "Happy Friday! The weekend is almost here!",
      "It's finally Friday! Time to unwind!",
      "Fantastic Friday! Celebrate your achievements!",
    ],
    Saturday: [
      "Happy Saturday! A perfect day for adventure!",
      "Super Saturday vibes! Enjoy your time!",
      "Saturday is here! Time to relax and have fun!",
    ],
  };

  // Holiday-specific greetings, including Kerala-specific holidays
  const holidayGreetings = {
    "1-1": "Happy New Year! Wishing you a year filled with joy and success!",
    "1-14": "Happy Pongal! Celebrate the harvest and happiness!",
    "1-26": "Happy Republic Day! Let’s honor the spirit of democracy.",
    "2-14": "Happy Valentine’s Day! Spread love and joy!",
    "3-8": "Happy Holi! Spread colors of joy and love today!",
    "3-21":
      "Eid Mubarak! May this special day bring peace and happiness to your life!",
    "4-14": "Happy Vishu! Wishing you a prosperous and joyful new year!",
    "4-22": "Happy Earth Day! Let’s cherish and protect our planet.",
    "5-1": "Happy International Workers’ Day! Celebrate the spirit of labor.",
    "6-5": "Happy World Environment Day! Let’s nurture our nature.",
    "6-21": "Happy International Yoga Day! Stay calm and balanced.",
    "8-15": "Happy Independence Day! Celebrate the spirit of freedom!",
    "8-29": "Happy Onam! May your life be filled with joy and prosperity!",
    "9-5": "Happy Teachers’ Day! A day to thank our mentors and guides.",
    "9-19": "Happy Ganesh Chaturthi! May Ganpati bring wisdom and success.",
    "9-28": "Happy Navaratri! Celebrate the victory of good over evil.",
    "10-0": "Happy Diwali! May your life be filled with light and happiness!",
    "10-24": "Happy United Nations Day! Working together for a better world.",
    "10-31": "Happy Halloween! Have a spooky and fun-filled day!",
    "11-1": "Happy Kerala Piravi! Celebrate the birth of Kerala state.",
    "11-14": "Happy Children’s Day! Let’s celebrate the joy of childhood.",
    "11-23":
      "Happy Thanksgiving! Take a moment to give thanks and enjoy a feast!",
    "12-25": "Merry Christmas! May your day be full of love and cheer!",
    "12-31":
      "Happy New Year’s Eve! Time to say goodbye to the year and welcome new beginnings!",
  };

  // Check for holidays
  const holidayKey = `${month}-${day}`;
  const holidayMessage = holidayGreetings[holidayKey] || "";

  // Randomly select a user mood
  const randomMood = userMoods[Math.floor(Math.random() * userMoods.length)];

  // Combine greeting with day-specific and mood messages
  const selectedDayGreeting =
    dayGreetings[dayOfWeek][
      Math.floor(Math.random() * dayGreetings[dayOfWeek].length)
    ];

  // Return the final greeting
  return holidayMessage
    ? `${greeting} ${holidayMessage} It seems like you're ${randomMood}.`
    : `${greeting} ${selectedDayGreeting} It seems like you're ${randomMood}.`;
}
