import Cookies from "js-cookie";
import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  DateRangePicker,
  Divider,
  Input,
  InputGroup,
  InputNumber,
  InputPicker,
  List,
  Modal,
  Nav,
  RadioTile,
  RadioTileGroup,
  SelectPicker,
} from "rsuite";
import constants, { alertError, alertInfo, getUserImage } from "../constants";
import {
  loadInventoryPlaces,
  loadInventoryUsers,
  loadProducts,
  loadUsers,
} from "../Loaders/GeneralLoder";
import CustomLoader from "../Widgets/CustomLoader";
import PageEndIcon from "@rsuite/icons/PageEnd";
import { FaLocationDot } from "react-icons/fa6";
import { Icon } from "@rsuite/icons";

class InventoryControl extends Component {
  state = {
    places: [],
    users: [],
    products: [],
    moves: [],
    selectedMovement: {},
    date: [new Date(), new Date()],
    loading: false,
    loadingText: null,
    modal: false,
    activeNav: "moves",
    productDisplayTitle: null,
    productDisplayList: null,
    filter: {},
    filterBasedUserPlace: false,
  };

  setLoading = (loading = true, loadingText = null) =>
    this.setState({ loading, loadingText });
  modal = (modal = true) => this.setState({ modal });

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    this.setLoading(true, "Loading Places");
    loadInventoryPlaces((places) =>
      this.setState({ places, loadingText: "Loading Users" }, () =>
        loadInventoryUsers((users) =>
          this.setState({ users, loadingText: "Loading Products" }, () =>
            loadProducts((products) =>
              this.setState({ products }, this.loadMoves)
            )
          )
        )
      )
    );
  };

  loadMoves = () => {
    this.setLoading(true, "Loading Moves");
    var { date } = this.state;
    const startDate = moment(date[0]).format("YYYY-MM-DD");
    const endDate = moment(date[1]).format("YYYY-MM-DD");

    // Construct the request body with the parameters
    const requestBody = {
      startDate: startDate,
      endDate: endDate,
    };

    fetch(constants.url + "inventory-moves-load", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(requestBody), // Send the parameters in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ moves: data.moves });
        this.setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  change = (where, what) => {
    var { selectedMovement } = this.state;
    selectedMovement[where] = what;
    this.setState({ selectedMovement });
  };
  changeFilter = (where, what) => {
    var { filter } = this.state;
    filter[where] = what;
    this.setState({ filter });
  };

  saveMove = () => {
    var { selectedMovement } = this.state;
    if (!selectedMovement.product_id) {
      alertError("Product not specified..");
      return;
    }
    if (!selectedMovement.qty) {
      alertError("Quantity not specified..");
      return;
    }
    if (!selectedMovement.from_place) {
      alertError("From place not specified..");
      return;
    }
    if (!selectedMovement.to_place) {
      alertError("To place not specified..");
      return;
    }
    this.setLoading(true, "Saving movement..");
    fetch(constants.url + "inventory-moves", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(selectedMovement),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alertInfo("Created Movement");
        this.setLoading(false);
        this.modal(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /*** THIS FUNCTION IS A MANUAL CALCULATOR , 
  WHICH DYNAMICALLY RETURNS THE PLACES TOTAL IN, OUT,
  AND PRODUCT BASED LOOPINGS.
  FROM THE MOVES SELECTED
  ****/
  filteredMoves = () => {
    let filteredMoves = this.state.moves; // Use let since it's being reassigned
    const { filter } = this.state;

    if (filter?.from_place) {
      filteredMoves = filteredMoves.filter(
        (move) => move.from_place?.id === filter.from_place
      );
    }

    if (filter?.from_user) {
      filteredMoves = filteredMoves.filter(
        (move) => move.from_user?.id === filter.from_user
      );
    }

    if (filter?.to_place) {
      filteredMoves = filteredMoves.filter(
        (move) => move.to_place?.id === filter.to_place
      );
    }

    if (filter?.to_user) {
      filteredMoves = filteredMoves.filter(
        (move) => move.to_user?.id === filter.to_user
      );
    }

    if (filter?.product_id) {
      filteredMoves = filteredMoves.filter(
        (move) => move.product_id === filter.product_id
      );
    }

    if (filter?.remarks) {
      filteredMoves = filteredMoves.filter((move) =>
        move.remarks.toLowerCase().includes(filter.remarks.toLowerCase())
      );
    }

    return filteredMoves;
  };

  finalPlaces = () => {
    const { filterBasedUserPlace, places } = this.state;

    if (!filterBasedUserPlace) {
      return places;
    }

    var moves = this.filteredMoves();

    const newPlaces = places.map((place) => {
      // Calculate from_quantity and to_quantity
      let fromQuantity = 0;
      let toQuantity = 0;

      // Filter moves related to this place
      const relatedMoves = moves.filter(
        (move) =>
          move.from_place?.id === place.id || move.to_place?.id === place.id
      );

      relatedMoves.forEach((move) => {
        if (move.from_place?.id === place.id) {
          fromQuantity += move.qty;
        }
        if (move.to_place?.id === place.id) {
          toQuantity += move.qty;
        }
      });

      // Update products total_in and total_out
      const updatedProducts = place.products.map((product) => {
        let totalIn = 0;
        let totalOut = 0;

        relatedMoves.forEach((move) => {
          if (move.product_id === product.id) {
            if (move.to_place?.id === place.id) {
              totalIn += move.qty;
            }
            if (move.from_place?.id === place.id) {
              totalOut += move.qty;
            }
          }
        });

        return {
          ...product,
          total_in: totalIn,
          total_out: totalOut,
        };
      });

      // Return the updated place
      return {
        ...place,
        from_quantity: fromQuantity,
        to_quantity: toQuantity,
        products: updatedProducts,
      };
    });

    return newPlaces;
  };

  /*** THIS FUNCTION IS A MANUAL CALCULATOR , 
  WHICH DYNAMICALLY RETURNS THE USERS TOTAL IN, OUT,
  AND PRODUCT BASED LOOPINGS.
  FROM THE MOVES SELECTED
  ****/
  finalUsers = () => {
    const { filterBasedUserPlace, users } = this.state;

    if (!filterBasedUserPlace) {
      return users;
    }

    var moves = this.filteredMoves();

    const newUsers = users.map((user) => {
      // Calculate from_quantity and to_quantity
      let fromQuantity = 0;
      let toQuantity = 0;

      // Filter moves related to this user
      const relatedMoves = moves.filter(
        (move) => move.from_user?.id === user.id || move.to_user?.id === user.id
      );

      relatedMoves.forEach((move) => {
        if (move.from_user?.id === user.id) {
          fromQuantity += move.qty;
        }
        if (move.to_user?.id === user.id) {
          toQuantity += move.qty;
        }
      });

      // Update products total_in and total_out
      const updatedProducts = user.products.map((product) => {
        let totalIn = 0;
        let totalOut = 0;

        relatedMoves.forEach((move) => {
          if (move.product_id === product.id) {
            if (move.to_user?.id === user.id) {
              totalIn += move.qty;
            }
            if (move.from_user?.id === user.id) {
              totalOut += move.qty;
            }
          }
        });

        return {
          ...product,
          total_in: totalIn,
          total_out: totalOut,
        };
      });

      // Return the updated user
      return {
        ...user,
        from_quantity: fromQuantity,
        to_quantity: toQuantity,
        products: updatedProducts,
      };
    });

    return newUsers;
  };

  render() {
    return (
      <div>
        <CustomLoader
          full
          text={this.state.loadingText}
          loading={this.state.loading}
        />
        <Divider>
          Inventory Control{" | "}
          <Button appearance="link" onClick={this.modal}>
            Create Move
          </Button>
          {" | "}
          <Button appearance="link" onClick={this.fetchData}>
            Refresh
          </Button>
        </Divider>

        <Nav
          appearance="tabs"
          activeKey={this.state.activeNav}
          onSelect={(activeNav) => this.setState({ activeNav })}
        >
          <Nav.Item eventKey="moves">Moves</Nav.Item>
          <Nav.Item eventKey="places">Places</Nav.Item>
          <Nav.Item eventKey="users">Users</Nav.Item>
        </Nav>

        {/* Moves filter and date range picker */}
        {this.state.activeNav == "moves" && (
          <InputGroup>
            <DateRangePicker
              placeholder="Select Dates"
              onChange={(date) => this.setState({ date }, this.loadMoves)}
              placement="auto"
              value={this.state.date}
              size="sm"
              showOneCalendar
              block
            />
            <Button onClick={() => this.setState({ filterModal: true })}>
              Filter
            </Button>
          </InputGroup>
        )}

        {/* Filter based toggle button for places and users */}
        {this.state.activeNav === "places" ||
        this.state.activeNav === "users" ? (
          <RadioTileGroup
            defaultValue="all_time"
            inline={true}
            aria-label="Filter Based Toggle"
            onChange={(value) =>
              this.setState({ filterBasedUserPlace: value == "filter_based" })
            }
          >
            <RadioTile label="All Time" value="all_time">
              All-Time matrix - {this.state.activeNav}
            </RadioTile>
            <RadioTile label="Filter Based" value="filter_based">
              Filtered matrix - {this.state.activeNav}
            </RadioTile>
          </RadioTileGroup>
        ) : (
          ""
        )}

        <br />

        {/* Moves */}
        {this.state.activeNav == "moves" &&
          this.filteredMoves().map((move, index) => (
            <div
              key={move.id}
              style={{
                width: "90vw",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "16px",
                marginBottom: "16px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
              }}
            >
              <strong style={{ fontSize: "18px", color: "#333" }}>
                {move.product?.name} (ID: {move?.product_id})
              </strong>
              <br />
              <i>{move.remarks}</i>
              <br />
              <div
                style={{ fontSize: "14px", color: "#777", marginTop: "4px" }}
              >
                <strong>Quantity:</strong> {move.qty}
              </div>
              <div
                style={{
                  width: "100%",
                  height: "50px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "8px",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <strong style={{ fontSize: "16px", color: "#555" }}>
                    {move.from_place?.name}
                  </strong>
                  <br />
                  <small style={{ fontSize: "14px", color: "#777" }}>
                    <img
                      src={getUserImage(move.from_user)}
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "20px",
                        margin: "8px",
                      }}
                    />
                    {move.from_user?.name ?? "No USER"}
                  </small>
                </div>
                <PageEndIcon
                  style={{ fontSize: "24px", color: "#888", margin: "0 12px" }}
                />
                <div style={{ textAlign: "right" }}>
                  <strong style={{ fontSize: "16px", color: "#555" }}>
                    {move.to_place?.name}
                  </strong>
                  <br />
                  <small style={{ fontSize: "14px", color: "#777" }}>
                    <img
                      src={getUserImage(move.to_user)}
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "20px",
                        margin: "8px",
                      }}
                    />
                    {move.to_user?.name ?? "No USER"}
                  </small>
                </div>
              </div>
              <div
                style={{
                  textAlign: "right",
                  marginTop: "12px",
                  fontSize: "14px",
                  color: "#777",
                  lineSpace: "10px",
                }}
              >
                <strong>{move.created_by.name}</strong> created{" "}
                {moment(move.created_at).fromNow()} (MOV#{move.id})
                <br />
                <i>{move.created_at}</i>
              </div>
            </div>
          ))}

        {/* Places */}
        {this.state.activeNav === "places" &&
          this.finalPlaces().map((place) => (
            <div
              key={place.id}
              style={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "16px",
                marginBottom: "16px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                maxWidth: "90vw",
              }}
              onClick={() =>
                this.setState({
                  productDisplayList: place.products,
                  productDisplayTitle: `Place: ${place.name}`,
                })
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <FaLocationDot
                  style={{
                    fontSize: "20px",
                    color: "#333",
                    marginRight: "8px",
                  }}
                />
                <strong style={{ fontSize: "18px", color: "#333" }}>
                  {place.name}
                </strong>
              </div>

              <div
                style={{ fontSize: "16px", color: "#555", marginBottom: "4px" }}
              >
                <strong>Total IN:</strong> {place.to_quantity}
              </div>
              <div
                style={{ fontSize: "16px", color: "#555", marginBottom: "4px" }}
              >
                <strong>Total OUT:</strong> {place.from_quantity}
              </div>
              <div style={{ fontSize: "16px", color: "#555" }}>
                <strong>Remaining:</strong>{" "}
                {parseInt(place.to_quantity) - parseInt(place.from_quantity)}
              </div>
            </div>
          ))}

        {/* Users */}
        {this.state.activeNav === "users" &&
          this.finalUsers().map((user) => (
            <div
              key={user.id}
              style={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "16px",
                marginBottom: "16px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                maxWidth: "90vw",
              }}
              onClick={() =>
                this.setState({
                  productDisplayList: user.products,
                  productDisplayTitle: `User: ${user.name}`,
                })
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <img
                  src={getUserImage(user)}
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "20px",
                    margin: "8px",
                  }}
                  alt="from user"
                />
                <strong style={{ fontSize: "18px", color: "#333" }}>
                  #{user.id} - {user.name}
                </strong>
              </div>

              <div
                style={{ fontSize: "16px", color: "#555", marginBottom: "4px" }}
              >
                <strong>Total IN:</strong> {user.to_quantity}
              </div>
              <div
                style={{ fontSize: "16px", color: "#555", marginBottom: "4px" }}
              >
                <strong>Total OUT:</strong> {user.from_quantity}
              </div>
              <div style={{ fontSize: "16px", color: "#555" }}>
                <strong>Remaining:</strong>{" "}
                {parseInt(user.to_quantity) - parseInt(user.from_quantity)}
              </div>
            </div>
          ))}

        {/* Move entry modal */}
        <Modal
          open={this.state.modal}
          onClose={() => this.modal(false)}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>Inventory Move</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Divider>Product &amp; Quantity</Divider>
            <InputGroup>
              <InputGroup.Addon>Product</InputGroup.Addon>
              <SelectPicker
                size="sm"
                value={this.state.selectedMovement.product_id}
                onChange={(value) => this.change("product_id", value)}
                data={this.state.products.map((product) => ({
                  label: product.name,
                  value: product.id,
                }))}
              />
              <InputGroup.Addon>QTY</InputGroup.Addon>
              <InputNumber
                block
                value={this.state.selectedMovement.qty}
                onChange={(value) => this.change("qty", value)}
              />
            </InputGroup>

            <Divider>Place</Divider>
            <InputGroup>
              <InputGroup.Addon>From</InputGroup.Addon>
              <SelectPicker
                size="sm"
                value={this.state.selectedMovement.from_place}
                onChange={(value) => this.change("from_place", value)}
                data={this.state.places.map((place) => ({
                  label: place.name,
                  value: place.id,
                }))}
              />
              <InputGroup.Addon>To</InputGroup.Addon>
              <SelectPicker
                size="sm"
                value={this.state.selectedMovement.to_place}
                onChange={(value) => this.change("to_place", value)}
                data={this.state.places.map((place) => ({
                  label: place.name,
                  value: place.id,
                }))}
              />
            </InputGroup>

            <Divider>User</Divider>
            <InputGroup>
              <InputGroup.Addon>From</InputGroup.Addon>
              <SelectPicker
                size="sm"
                value={this.state.selectedMovement.from_user}
                onChange={(value) => this.change("from_user", value)}
                data={this.state.users.map((user) => ({
                  label: user.name,
                  value: user.id,
                }))}
              />
              <InputGroup.Addon>To</InputGroup.Addon>

              <SelectPicker
                size="sm"
                value={this.state.selectedMovement.to_user}
                onChange={(value) => this.change("to_user", value)}
                data={this.state.users.map((user) => ({
                  label: user.name,
                  value: user.id,
                }))}
              />
            </InputGroup>

            <Divider>Remarks</Divider>
            <Input
              as="textarea"
              block
              value={this.state.selectedMovement.remarks}
              onChange={(value) => this.change("remarks", value)}
            />
            <br />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.saveMove}
              appearance="primary"
              disabled={this.state.loading}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* productDisplayList */}

        <Modal
          open={this.state.productDisplayList != null}
          onClose={() => this.setState({ productDisplayList: null })}
        >
          <Modal.Header>
            <Modal.Title>{this.state.productDisplayTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <List>
              {this.state.productDisplayList?.map((product, index) => (
                <List.Item>
                  <strong>{product.name}</strong>
                  <br />
                  <small>Total In</small>: {product.total_in}
                  <br />
                  <small>Total Out</small>: {product.total_out}
                  <br />
                  <small>Remaining</small>:{" "}
                  {parseFloat(product.total_in) - parseFloat(product.total_out)}
                  <br />
                </List.Item>
              ))}
            </List>
          </Modal.Body>
        </Modal>

        {/* Filter modal */}
        <Modal
          open={this.state.filterModal}
          onClose={() => this.setState({ filterModal: false })}
        >
          <Modal.Header>
            <Modal.Title>
              <span
                style={{
                  fontWeight: "bold",
                  color: "red",
                  fontSize: "18px !important",
                }}
              >
                Filter Moves
              </span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Divider>From Filter</Divider>
            <i>Filter From Place</i>
            <InputPicker
              block
              value={this.state.filter.from_place}
              onChange={(value) => this.changeFilter("from_place", value)}
              data={this.state.places.map((place) => ({
                label: place.name,
                value: place.id,
              }))}
            />

            <i>Filter From User</i>
            <InputPicker
              block
              value={this.state.filter.from_user}
              onChange={(value) => this.changeFilter("from_user", value)}
              data={this.state.users.map((user) => ({
                label: user.name,
                value: user.id,
              }))}
            />
            <Divider>To Details</Divider>
            <i>Filter To Place</i>
            <InputPicker
              block
              value={this.state.filter.to_place}
              onChange={(value) => this.changeFilter("to_place", value)}
              data={this.state.places.map((place) => ({
                label: place.name,
                value: place.id,
              }))}
            />

            <i>Filter To User</i>
            <InputPicker
              block
              value={this.state.filter.to_user}
              onChange={(value) => this.changeFilter("to_user", value)}
              data={this.state.users.map((user) => ({
                label: user.name,
                value: user.id,
              }))}
            />
            <Divider>Product Details</Divider>
            <i>Filter Product</i>
            <InputPicker
              block
              value={this.state.filter.product_id}
              onChange={(value) => this.changeFilter("product_id", value)}
              data={this.state.products.map((product) => ({
                label: product.name,
                value: product.id,
              }))}
            />
            <i>Filter Remarks</i>
            <Input
              as="textarea"
              block
              value={this.state.filter.remarks}
              onChange={(value) => this.changeFilter("remarks", value)}
            />
            <hr />
          </Modal.Body>
          <Modal.Footer>
            <Button
              block
              appearance="primary"
              onClick={() => this.setState({ filter: {} })}
            >
              CLEAR FILTERS
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default InventoryControl;
