import Cookies from "js-cookie";
import React, { Component } from "react";
import { InputPicker, Modal, Button } from "rsuite";
import constants, { alertError, alertInfo } from "../constants";

class SaleDeliveryAssign extends Component {
  state = {
    modal: false,
    loading: false,
    recentlyAssignedUser: Cookies.get("recently-assigned-delivery-user", null),
  };

  loading = (loading = true) => this.setState({ loading });

  updateDeliveryBy = (delivery_by) => {
    this.loading();

    var reqData = {
      delivery_by,
    };
    fetch(constants.url + `sales/${this.props.sale.id}/delivery`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((response) => response.json())
      .then((sale) => {
        if (this.props.refreshSale) {
          this.props.refreshSale();
        }
        if (this.props.onChange) {
          this.props.onChange(delivery_by);
        }

        this.setState({ modal: false, recentlyAssignedUser: delivery_by });
        this.loading(false);
        Cookies.set("recently-assigned-delivery-user", delivery_by);
        alertInfo("Success");
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
        alertError(`Failed ${error?.message}`);
      });
  };

  render() {
    var { users, sale } = this.props;
    var currentUser = users.find((u) => u.id == sale.delivery_by) ?? null;
    var recentlyAssignedUser =
      users.find((u) => u.id == this.state.recentlyAssignedUser) ?? null;

    if (sale.status !== "delivery") {
      return "";
    }

    return (
      <span>
        <Button
          appearance="link"
          onClick={() => this.setState({ modal: true })}
        >
          DEL: {currentUser?.name ?? "Not Assigned"}
        </Button>

        <Modal
          open={this.state.modal}
          onClose={() => this.setState({ modal: false })}
        >
          <Modal.Header>
            <Modal.Title>Delivery User Assignment for {sale.id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputPicker
              data={users.map((i) => ({
                label: i.name,
                value: i.id,
              }))}
              value={sale.delivery_by}
              onChange={(value) => this.updateDeliveryBy(value)}
              disabled={this.state.loading}
              placement="auto"
              placeholder={`Select users from ${users.length}`}
            />
            <br />
            {recentlyAssignedUser ? (
              <Button
                disabled={this.state.loading}
                appearance="link"
                onClick={() => this.updateDeliveryBy(recentlyAssignedUser?.id)}
              >
                Recently used: {recentlyAssignedUser?.name}
              </Button>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button> */}
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

export default SaleDeliveryAssign;
