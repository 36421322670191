import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  InputPicker,
  Loader,
  Modal,
  Panel,
  Stack,
} from "rsuite";
import CustomerListItem from "../../../components/CustomerListItem";
import constants, { alertError } from "../../../constants";
import { getRouteZones } from "../../../Helpers/CoordinateHelper";
import { getFavCustomers, truncatedString } from "../../../Helpers/Utilities";
import { loadRoutes } from "../../../Loaders/GeneralLoder";
import CustomLoader from "../../../Widgets/CustomLoader";
import CustomerView from "./customer-view";

import {
  InfoWindow,
  Marker,
  Circle,
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  Polygon,
  Polyline,
  DirectionsRenderer,
  MarkerClusterer,
  PolygonF,
  PolylineF,
  MarkerClustererF,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import moment from "moment";

class SalesHome extends Component {
  state = {
    sales: [],
    routes: [],
    route: null,
    status: null,
    loading: false,
    selectedCustomer: null,
    mapModal: false,
    deliveryFilter: "ALL",
  };

  loading = (loading = true) => this.setState({ loading });

  componentDidMount = () => {
    loadRoutes((routes) => this.setState({ routes }));
  };

  loadSales = () => {
    this.loading();
    var { route, status } = this.state;

    var reqData = {
      route,
      status,
    };

    fetch(constants.url + "load-sales", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ sales: data.data });
        this.loading(false);
      })
      .catch((error) => {
        console.error(error);
        alertError("Failed to load sales..");
        this.loading(false);
      });
  };

  render() {
    var { deliveryFilter, status, route, routes, selectedCustomer } =
      this.state;

    var selectedRoute = routes.find((r) => r.id == route) ?? null;

    const sales = this.state.sales.filter((sale) =>
      status === "delivery"
        ? deliveryFilter === "ALL" ||
          (deliveryFilter === "OLD" &&
            sale?.delivery_date &&
            moment(sale.delivery_date).isBefore(moment(), "day"))
        : true
    );

    return (
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>Sales History</span>
            <ButtonGroup>
              <Button onClick={this.loadSales}>Refresh</Button>
              <Button
                appearance="primary"
                color="green"
                onClick={() => this.setState({ mapModal: true })}
              >
                MAP
              </Button>
            </ButtonGroup>
          </Stack>
        }
      >
        <CustomLoader full text="Loading data.." loading={this.state.loading} />
        <div>
          <i>Select Route</i>
          <br />
          <InputPicker
            placeholder="Select route"
            value={route}
            data={routes.map((route) => ({
              label: route.route_name,
              value: route.id,
            }))}
            onChange={(route) => this.setState({ route })}
          />

          <i>Select Sale Status</i>
          <br />

          <InputPicker
            data={constants.salesStatuses}
            value={this.state.status}
            onChange={(status) => this.setState({ status })}
            disabled={this.state.loading}
            placement="auto"
          />

          <br />
          {this.state.status == "delivery" ? (
            <InputPicker
              data={[
                { label: "All Deliveries", value: "ALL" },
                { label: "Old Deliveries Only", value: "OLD" },
              ]}
              value={this.state.deliveryFilter}
              onChange={(deliveryFilter) => this.setState({ deliveryFilter })}
              disabled={this.state.loading}
              placement="auto"
            />
          ) : (
            ""
          )}

          <Divider>
            {sales?.length} Sales, with "{this.state.status}" status{" "}
          </Divider>
        </div>

        {sales.map((sale, index) => (
          <div key={`salelist-${index}`}>
            <CustomerListItem
              customer={sale.customer}
              favs={getFavCustomers(() => {})}
              onFavUpdate={(favs) =>
                Cookies.set("favs", JSON.stringify(favs), {
                  expires: 365,
                })
              }
              // Find all sales associated with this customer ID
              sales={sales.filter((d) => d.customer.id === sale.customer.id)}
              selectCustomer={() => {
                this.setState({ selectedCustomer: sale.customer });
              }}
            />
          </div>
        ))}

        {/* Lead & Customer View Drawer */}
        {selectedCustomer != null ? (
          <Drawer
            placement="bottom"
            size="full"
            open={selectedCustomer != null}
            onClose={() => this.setState({ selectedCustomer: null })}
          >
            <Drawer.Header>
              <Drawer.Title>View {selectedCustomer?.shop_name}</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              {selectedCustomer ? (
                <CustomerView
                  data={selectedCustomer}
                  back={() => this.setState({ selectedCustomer: null })}
                  currentLocation={this.props.currentLocation}
                  sessionCheck={this.props.sessionCheck}
                />
              ) : (
                ""
              )}
            </Drawer.Body>
          </Drawer>
        ) : (
          ""
        )}
        {/* Map modal */}
        <Modal
          open={this.state.mapModal}
          onClose={() => this.setState({ mapModal: false })}
        >
          <Modal.Header>
            <Modal.Title>Map View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "76vh",
              }}
              zoom={10}
              onLoad={(map) => {
                this.setState({ map });
                map.setCenter(this.props.currentLocation);
              }}
              onUnmount={() => this.setState({ map: null })}
              mapTypeId="roadmap"
            >
              {selectedRoute ? (
                <>
                  <PolygonF
                    options={{
                      paths: JSON.parse(selectedRoute?.cord1).map((coord) => ({
                        lat: parseFloat(coord.lat),
                        lng: parseFloat(coord.lng),
                      })),
                      strokeColor: "#1abc9c",
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillOpacity: 0,
                    }}
                  />
                  <PolylineF
                    path={getRouteZones(selectedRoute)?.verticalLine}
                    options={{
                      strokeColor: "#697987",
                      strokeOpacity: 1,
                      strokeWeight: 2,
                    }}
                  />
                  <PolylineF
                    path={getRouteZones(selectedRoute)?.horizontalLine}
                    options={{
                      strokeColor: "#697987",
                      strokeOpacity: 1,
                      strokeWeight: 2,
                    }}
                  />
                </>
              ) : (
                ""
              )}

              {sales?.map(({ customer }, index) => (
                <MarkerF
                  key={`D${index}`}
                  position={JSON.parse(customer.geo_location)}
                  icon={{
                    url:
                      customer.latest_sales_returns?.length > 0
                        ? constants.marker_pink
                        : constants.marker_red,
                    scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                  }}
                  label={{
                    text: truncatedString(customer.shop_name),
                    color: "black",
                    fontSize: "8",
                  }}
                  onClick={() => {
                    setTimeout(() => {
                      if (this.state.clickedCustomer?.id !== customer.id) {
                        this.setState({ clickedCustomer: customer });
                      }
                    }, 100); // delay by 100ms
                  }}
                  onDblClick={() =>
                    (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                      JSON.parse(customer.geo_location).lat
                    },${
                      JSON.parse(customer.geo_location).lng
                    }&travelmode=motorcycle`)
                  }
                >
                  {/* Render InfoWindow only for the clickedCustomer */}
                  {this.state.clickedCustomer?.id === customer.id && (
                    <InfoWindowF
                      position={JSON.parse(customer.geo_location)} // Explicit position
                      onCloseClick={() =>
                        this.setState({ clickedCustomer: null })
                      }
                    >
                      <span style={{ padding: "4px" }}>
                        <CustomerListItem
                          key={customer.id}
                          customer={customer}
                          favs={this.state?.favs ?? []}
                          onFavUpdate={(favs) => this.setState({ favs })}
                          selectCustomer={() => {
                            this.setState({ selectedCustomer: customer });
                          }}
                        />
                        <br />
                        <Button
                          appearance="primary"
                          color="green"
                          onClick={() =>
                            (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                              JSON.parse(customer.geo_location).lat
                            },${
                              JSON.parse(customer.geo_location).lng
                            }&travelmode=motorcycle`)
                          }
                          size="xs"
                        >
                          NAVIGATE
                        </Button>
                        <br />
                      </span>
                    </InfoWindowF>
                  )}
                </MarkerF>
              ))}
              <MarkerF
                position={this.props.currentLocation}
                label={{
                  text: "You",
                  fontSize: "10",
                }}
              />
            </GoogleMap>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button> */}
          </Modal.Footer>
        </Modal>
      </Panel>
    );
  }
}

export default SalesHome;
