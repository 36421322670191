import moment from "moment";
import React, { Component } from "react";
import { List, Tag } from "rsuite";

class UserRevisions extends Component {
  state = {};
  render() {
    return (
      <div>
        <Tag size="lg">
          Customer revisions:{" "}
          {
            this.props.data.revisions.filter((i) => i.customer_id !== null)
              .length
          }
        </Tag>{" "}
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Tag size="lg">
          Lead revisions:{" "}
          {this.props.data.revisions.filter((i) => i.lead_id !== null).length}
        </Tag>
        <hr />
        <List>
          {this.props.data.revisions.map((r) => (
            <List.Item key={r.id}>
              {r.customer_id ? (
                <Tag color="green">Customer #{r.customer_id}</Tag>
              ) : r.lead_id ? (
                <Tag color="blue">Lead #{r.lead_id}</Tag>
              ) : r.lead_followup_id ? (
                <Tag color="blue">Lead Followup#{r.lead_followup_id}</Tag>
              ) : (
                ""
              )}
              <br />
              {moment(r.created_at).fromNow()}
            </List.Item>
          ))}
        </List>
      </div>
    );
  }
}

export default UserRevisions;
