import React, { Component } from "react";
import { Input, InputGroup } from "rsuite";
import constants, { alertError, alertInfo, getUserImage } from "../constants";
import "./SaleTracks.css";
import SendRoundIcon from "@rsuite/icons/SendRound";
import Cookies from "js-cookie";
import InputAI from "../Modules/GoogleAI/InputAI";

class SaleTracks extends Component {
  state = {
    limitedList: true,
    loading: false,
    notes: "",
  };

  loading = (loading = true) => this.setState({ loading });

  submitNotes = () => {
    var { notes } = this.state;
    if (notes?.length == 0) {
      alertError("Empty notes, are not accepted!");
    }
    fetch(constants.url + "sales/" + this.props?.sale?.id + "/notes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify({ notes }),
    })
      .then((response) => response.json())
      .then((data) => {
        alertInfo("Notes added");
        this.setState({ notes: "" });
        this.loading(false);
        this.props.refresh();
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
        alertError("Unable to create  notes");
      });
  };

  render() {
    const { tracks } = this.props;
    const { limitedList } = this.state;

    // Limit the list display
    const displayedTracks = tracks && limitedList ? tracks.slice(0, 2) : tracks;

    return (
      <div className="sale-tracks-container">
        <InputGroup style={{ marginBottom: 10 }}>
          <Input
            placeholder="Notes"
            value={this.state.notes}
            onChange={(notes) => this.setState({ notes })}
          />

          <InputAI
            buttonOnly={true}
            value={this.state.notes}
            onChange={(notes) => this.setState({ notes })}
          />
          <InputGroup.Button
            disabled={this.state.loading}
            onClick={this.submitNotes}
          >
            <SendRoundIcon />
          </InputGroup.Button>
        </InputGroup>
        {!tracks || tracks.length == 0 ? (
          ""
        ) : (
          <>
            {" "}
            <div className="track-list">
              {displayedTracks.map((track) => (
                <div key={track.id} className="track-item">
                  <div className="track-header">
                    <img
                      src={getUserImage(track.created_by)}
                      alt={track.created_by.name}
                      className="user-image"
                    />
                    <div className="user-info">
                      <span className="user-name">{track.created_by.name}</span>
                      <span className="track-date">
                        {new Date(track.created_at).toLocaleDateString("en-GB")}
                      </span>
                    </div>
                  </div>
                  <div className="track-details">
                    <div className="track-type">{track.type}</div>
                    <div className="track-remarks">{track.remarks}</div>
                  </div>
                </div>
              ))}
            </div>
            <button
              onClick={() => this.setState({ limitedList: !limitedList })}
              className="toggle-button"
            >
              Show {limitedList ? "More" : "Less"} Tracks
            </button>
          </>
        )}
      </div>
    );
  }
}

export default SaleTracks;
