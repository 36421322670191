import React, { Component } from "react";
import { IoMdAddCircle } from "react-icons/io";
import { Button, Input, InputNumber, Modal, Rate, TagPicker } from "rsuite";
import { alertError, alertInfo } from "../constants";
import { loadHashTags } from "../Loaders/GeneralLoder";
import { createLead, createHashTag } from "../Loaders/GeneralSaver";

class QuickCreateLead extends Component {
  state = {
    modal: false,
    data: {},
    hashTags: [],
    loading: false,
  };

  loading = (loading = true) => this.setState({ loading });

  componentDidMount = () => {
    loadHashTags((hashTags) => this.setState({ hashTags }));
  };

  modal = (modal = true) => {
    this.setState({
      modal,
      data: {
        geolocation: JSON.stringify(this.props.currentLocation),
        shop_name: "",
        phone: "",
        remarks: "",
        next_visit: "",
        notes: "",
        rate: 2,
        address: "",
      },
    });
  };

  changeData = (where, what) => {
    var { data } = this.state;
    data[where] = what;
    this.setState({ data });
  };

  save = () => {
    this.loading();
    createLead(
      this.state.data,
      () => {
        this.loading(false);
        this.modal(false);
        alertInfo("Lead Created Successfully!");
      },
      () => {
        this.loading(false);
        alertError("An error occurred while saving the lead.");
      }
    );
  };

  render() {
    var { data, modal } = this.state;
    return (
      <>
        <Button onClick={this.modal}>
          <IoMdAddCircle /> Create Lead
        </Button>

        <Modal open={modal} onClose={() => this.modal(false)}>
          <Modal.Header>
            <Modal.Title>Quick Create Lead Spot</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Input
              placeholder="Shop name"
              block
              onChange={(value) => {
                this.changeData("shop_name", value);
              }}
              value={data.shop_name}
            />
            <InputNumber
              placeholder="Contact number"
              block
              onChange={(value) => {
                if (value.length > 10) {
                  alertError("Max 10 digits");
                  return;
                }
                this.changeData("phone", value);
              }}
              value={data.phone}
            />
            <br />
            <Input
              placeholder="Notes"
              block
              onChange={(value) => {
                this.changeData("notes", value);
              }}
              value={data.notes}
            />
            <br />
            <TagPicker
              creatable
              data={this.state.hashTags?.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
              onCreate={(hashTags) => {
                createHashTag(hashTags[hashTags.length - 1], (newHashTags) => {
                  this.setState({ hashTags: newHashTags });
                });
              }}
              block
              placeholder="Remarks"
              onChange={(value) => {
                this.changeData("remarks", JSON.stringify(value));
              }}
              value={JSON.parse(
                data?.remarks?.startsWith("[")
                  ? data?.remarks
                  : data?.remarks?.length > 0
                  ? `["${data?.remarks}"]`
                  : "[]"
              )}
            />
            <Rate
              onChange={(value) => {
                this.changeData("rate", value);
              }}
              onChangeActive={(value) => {
                this.changeData("rate", value);
              }}
              value={data.rate}
            />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.save}
              appearance="primary"
              disabled={this.state.loading}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default QuickCreateLead;
