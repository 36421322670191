import React, { Component } from "react";
import { Divider, Grid, Row, Col, Button, Progress } from "rsuite";
import { loadStats } from "../../Loaders/GeneralLoder";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { greetingsNow } from "../../Helpers/Utilities";

const greeting = greetingsNow();

class Dashboard extends Component {
  state = {
    stats: null,
  };

  componentDidMount = () => {
    loadStats((stats) => this.setState({ stats }));
  };

  transformData = (events, eventsDone, customers) => {
    // Create a map to hold aggregated data by day
    const dataMap = new Map();

    const formatDay = (dateString) => {
      // Extract the day of the month from the date string
      const date = new Date(dateString);
      return date.getDate(); // Returns the day as a number (1-31)
    };

    const aggregateData = (data, key) => {
      data.forEach((item) => {
        const formattedDay = formatDay(item.day); // Format the day
        if (!dataMap.has(formattedDay)) {
          dataMap.set(formattedDay, { day: formattedDay });
        }
        dataMap.get(formattedDay)[key] = item.count;
      });
    };

    aggregateData(events, "Events");
    aggregateData(eventsDone, "Events Done");
    aggregateData(customers, "Customers");

    return Array.from(dataMap.values());
  };

  render() {
    const { stats } = this.state;
    const combinedData = stats
      ? this.transformData(stats.events, stats.eventsDone, stats.customers)
      : [];

    var customers = stats?.customers?.reduce(
      (total, item) => total + item.count,
      0
    );

    var minCustomer =
      this.props.sessionCheck?.active_employment?.employment_type
        ?.min_customers;
    var customerPercentage = (100 / minCustomer) * customers;

    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split("T")[0];

    // Safely access payments and default to an empty array if undefined
    const todayPaymentsList = (stats?.payments || []).filter(
      (payment) => payment.day === today
    );

    // Check if there are any payments for today
    const todayPayments =
      todayPaymentsList.length > 0
        ? todayPaymentsList[0]
        : {
            total_amount: 0,
            payments_cash: 0,
            payments_upi: 0,
          };

    return (
      <div>
        <div className="greeting-container">
          <strong className="greeting-name">
            Hello {this.props.sessionCheck?.user?.name.split(" ")[0]}!
          </strong>
          <br />
          <i className="greeting-message">{greeting}</i>
        </div>
        <br />
        {minCustomer > 0 && (
          <div>
            <strong>Incentive Scale:</strong>
            <Progress.Line
              percent={customerPercentage.toFixed(2)}
              strokeColor={
                customerPercentage < 50
                  ? "red"
                  : customerPercentage >= 100
                  ? "green"
                  : "blue"
              }
              status="active"
            />
          </div>
        )}
        <h1>Full Month Stats</h1>
        {stats && (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={combinedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="Events"
                name="Events"
                stroke="#8884d8"
              />
              <Line
                type="monotone"
                dataKey="Events Done"
                name="Events Done"
                stroke="#82ca9d"
              />
              <Line
                type="monotone"
                dataKey="Customers"
                name="Customers"
                stroke="#ffc658"
              />
            </LineChart>
          </ResponsiveContainer>
        )}{" "}
        {/* Render the Line component only if stats is available */}
        <Divider>Other Stats</Divider>
        <div className="stats-container">
          <div className="stat-item red">
            <h3>Customers</h3>
            <p>{customers}</p>
          </div>

          <div className="stat-item yellow">
            <h3>Visits</h3>
            <p>
              {stats?.events?.reduce((total, item) => total + item.count, 0)}
            </p>
          </div>

          {this.props.sessionCheck?.role === "Admin" && (
            <div className="stat-item green">
              <h3>Payments</h3>
              <p>
                {stats?.payments?.reduce(
                  (total, item) => total + item.total_amount,
                  0
                )}
              </p>
            </div>
          )}

          <div className="stat-item green">
            <h3>Volume</h3>
            <p>0</p>
          </div>

          {this.props.sessionCheck?.active_employment?.employment_type
            ?.earnings !== 0 && (
            <div className="stat-item green">
              <h3>Earnings</h3>
              <p>{stats?.totalCommission}</p>
            </div>
          )}

          <div className="today-payments">
            <h3>Today's Payments</h3>
            <p>Date: {today}</p>
            <p>Total Payments: {todayPayments.total_amount}</p>
            <p>Cash Payments: {todayPayments.payments_cash}</p>
            <p>UPI Payments: {todayPayments.payments_upi}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
