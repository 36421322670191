import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Drawer,
  Input,
  InputNumber,
  InputPicker,
  Modal,
  SelectPicker,
  TagPicker,
} from "rsuite";
import LeadListItem from "../../components/LeadListItem";
import constants, { alertError, haversineDistance } from "../../constants";
import { loadHashTags, loadRoutes } from "../../Loaders/GeneralLoder";
import CustomLoader from "../../Widgets/CustomLoader";
import HomeLeadView from "../sub-pages/mobile/home-lead-view";

class DataManipulation extends Component {
  state = {
    loading: false,
    loadingText: "",
    numberOfResults: 50,
    page: 1,
    notWorthyHashTags: "",
    data: [],
    selectedData: null,
    viewLead: null,
    hashTags: [],
    routes: [],
    route: null,
    convertedLeads: [],
    top10: {},
    radius: 500,
    editing: false,
  };

  loading = (loading = true, loadingText = "") =>
    this.setState({ loading, loadingText });

  componentDidMount = () => {
    loadHashTags((hashTags) => this.setState({ hashTags }));
    loadRoutes((routes) => this.setState({ routes }));
  };

  loadConvertedLeads = () => {
    var { route } = this.state;
    this.loading(true, "Loading converted leads");

    fetch(constants.url + `dm-converted-leads/${route}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.loading(false);
        this.setState(
          { convertedLeads: data.data },
          this.validateConvertedLeads
        );
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
        alertError("Failed");
      });
  };

  validateConvertedLeads = () => {
    var { convertedLeads } = this.state;
    var top10 = {
      rate: [],
      hashTags: [],
      shopNames: [],
      notes: [],
      remarksTelex: [],
      remarksCRE: [],
      response: [],
      geoSpots: [],
    };

    // Maps to count occurrences of hashtags, shop name terms, and note terms
    const rateCounts = {};
    const hashtagCounts = {};
    const shopNameCounts = {};
    const noteCounts = {};
    const remarksTelexCounts = {};
    const remarksCRECounts = {};
    const responseCount = {};
    const geoSpotCounts = {};

    convertedLeads.forEach((lead) => {
      //rate count calculation
      //response rate counts

      rateCounts[lead.rate + ""] = (rateCounts[lead.rate + ""] || 0) + 1;

      // Handle hashtags from remarks
      if (lead.remarks) {
        try {
          const hashtags = JSON.parse(lead.remarks);
          if (Array.isArray(hashtags)) {
            hashtags.forEach((hashtag) => {
              hashtagCounts[hashtag] = (hashtagCounts[hashtag] || 0) + 1;
            });
          }
        } catch (error) {
          console.error(`Error parsing remarks for lead ID ${lead.id}:`, error);
        }
      }

      // Handle terms from shop_name
      if (lead.shop_name) {
        const terms = lead.shop_name.split(/\s+/); // Split by whitespace
        terms.forEach((term) => {
          const cleanTerm = term.trim().toLowerCase();
          // Allow only meaningful words (alphabetic terms, at least 2 characters long)
          if (/^[a-z]{2,}$/.test(cleanTerm)) {
            shopNameCounts[cleanTerm] = (shopNameCounts[cleanTerm] || 0) + 1;
          }
        });
      }

      // Handle terms from notes
      if (lead.notes) {
        const terms = lead.notes.split(/\s+/); // Split by whitespace
        terms.forEach((term) => {
          const cleanTerm = term.trim().toLowerCase();
          // Allow only meaningful words (alphabetic terms, at least 2 characters long)
          if (/^[a-z]{2,}$/.test(cleanTerm)) {
            noteCounts[cleanTerm] = (noteCounts[cleanTerm] || 0) + 1;
          }
        });
      }

      // Handle terms from followup remarks telex
      if (lead.followups) {
        lead.followups.forEach((followup) => {
          const termsTelex = followup?.remark?.split(/\s+/) ?? []; // Split by whitespace
          const termsCRE = followup?.event_remark?.split(/\s+/) ?? []; // Split by whitespace

          termsTelex.forEach((term) => {
            const cleanTerm = term.trim().toLowerCase();
            // Allow only meaningful words (alphabetic terms, at least 2 characters long)
            if (/^[a-z]{4,}$/.test(cleanTerm)) {
              remarksTelexCounts[cleanTerm] =
                (remarksTelexCounts[cleanTerm] || 0) + 1;
            }
          });

          termsCRE.forEach((term) => {
            const cleanTerm = term.trim().toLowerCase();
            // Allow only meaningful words (alphabetic terms, at least 2 characters long)
            if (/^[a-z]{4,}$/.test(cleanTerm)) {
              remarksCRECounts[cleanTerm] =
                (remarksCRECounts[cleanTerm] || 0) + 1;
            }
          });

          //response rate counts
          const roundedResponse =
            Math.ceil(parseFloat(followup.response) / 5) * 5;
          responseCount[roundedResponse + ""] =
            (responseCount[roundedResponse + ""] || 0) + 1;
        });
      }

      // Handle geoSpots
      if (lead.customer) {
        try {
          const location = JSON.parse(lead.customer.geo_location); // Parse geolocation as { lat, lng }
          const { radius } = this.state; // Radius in meters for grouping spots
          let foundSpot = false;

          for (const spot of Object.keys(geoSpotCounts)) {
            const [spotLat, spotLng] = spot.split(",").map(Number);
            const distance = haversineDistance(
              { lat: location.lat, lng: location.lng },
              { lat: spotLat, lng: spotLng }
            );

            if (distance <= radius) {
              // If within radius, count it as part of this spot
              geoSpotCounts[spot] = (geoSpotCounts[spot] || 0) + 1;
              foundSpot = true;
              break;
            }
          }

          if (!foundSpot) {
            // If no nearby spot is found, create a new spot
            const key = `${location.lat.toFixed(3)},${location.lng.toFixed(3)}`;
            geoSpotCounts[key] = 1;
          }
        } catch (error) {
          console.error(
            `Error parsing geolocation for lead ID ${lead.id}:`,
            error
          );
        }
      }
    });

    // Helper to sort and extract top 10 items
    const getTop10 = (counts) =>
      Object.entries(counts)
        .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
        .slice(0, 10) // Limit to top 10
        .map((term) => term); // Extract only the term

    // Get top 10 for each category
    top10.hashTags = getTop10(hashtagCounts);
    top10.shopNames = getTop10(shopNameCounts);
    top10.notes = getTop10(noteCounts);
    top10.remarksTelex = getTop10(remarksTelexCounts);
    top10.remarksCRE = getTop10(remarksCRECounts);
    top10.response = getTop10(responseCount);
    top10.rate = getTop10(rateCounts);
    top10.geoSpots = getTop10(geoSpotCounts);

    // Set the state or use the result as needed
    console.log("Top 10 Data:", top10);
    this.setState({ top10 });
  };

  sendReq = () => {
    var { numberOfResults, page, notWorthyHashTags } = this.state;
    this.loading(true, "Sending request");

    var reqData = {
      numberOfResults, //default 50
      page, //default 5
      notWorthyHashTags,
    };
    fetch(constants.url + "dm-similar-leads", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify({ reqData }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.loading(false);
        this.setState({ data: data.data });
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
        alertError("Failed");
      });
  };
  render() {
    if (!this.props.sessionCheck.roles.includes("DATA_MANIPULATION_MODULE")) {
      return "";
    }

    var { top10, editing } = this.state;
    return (
      <div style={{ maxWidth: "100vw", overFlowX: "hidden" }}>
        <CustomLoader
          full
          text={this.state.loadingText}
          loading={this.state.loading}
        />
        <Divider>Select Route ({this.state.routes.length})</Divider>
        <SelectPicker
          size="sm"
          // searchable={false}
          data={this.state.routes.map((route) => ({
            label: route.route_name,
            value: route.id,
          }))}
          value={this.state.route}
          onChange={(route) =>
            this.setState({ route }, this.loadConvertedLeads)
          }
        />
        <i>GEO Radius</i>
        <br />
        <InputNumber
          placeholder="GEO Radius"
          value={this.state.radius}
          onChange={(radius) => this.setState({ radius })}
        />
        <i>Number of results</i>
        <br />
        <InputNumber
          placeholder="Number of results"
          value={this.state.numberOfResults}
          onChange={(numberOfResults) => this.setState({ numberOfResults })}
        />
        <i>Page</i>
        <br />
        <InputNumber
          placeholder="Page"
          value={this.state.page}
          onChange={(page) => this.setState({ page })}
        />
        <i>Not Worthy hash tags</i>
        <br />
        <Input
          placeholder="Not worthy hash tags"
          value={this.state.notWorthyHashTags}
          onChange={(notWorthyHashTags) => this.setState({ notWorthyHashTags })}
        />
        <Divider>
          Converted Data from {this.state.convertedLeads?.length} leads{" "}
          <Button
            appearance="link"
            onClick={() => this.setState({ editing: !this.state.editing })}
          >
            {this.state.editing ? "done" : "edit"}
          </Button>
        </Divider>
        <i>Top 10 Hash Tags</i>
        <br />
        <TagPicker
          creatable
          data={top10.hashTags?.map((item) => ({
            label: `${item[0]} (${item[1]})`,
            value: item,
          }))}
          // onCreate={(hashTags) => {
          //   createHashTag(hashTags[hashTags.length - 1], (newhashTags) =>
          //     this.setState({ hashTags: newhashTags })
          //   );
          // }}
          block
          placeholder="Top #tags"
          // onChange={(value) => {
          //   console.log(value);
          //   this.changeVal("hashTags", value);
          // }}
          value={top10?.hashTags ?? []}
          plaintext={!editing}
        />
        <Button disabled={this.state.loading} onClick={this.sendReq}>
          SEND REQ
        </Button>
        <hr />
        <Divider>Data Listing ({this.state.data.length})</Divider>
        {this.state.data.map((item) => (
          <div
            key={item.lead.id}
            style={{ border: "1px solid #ededed", marginBottom: "10px" }}
          >
            <span>
              <strong>
                {item.lead_id}-{item.lead.shop_name}
              </strong>
              <i>&nbsp;&nbsp;~MATCH WITH~&nbsp;&nbsp;</i>
              <strong>
                {item.converted_lead_id} - {item.converted_lead.shop_name}
              </strong>
            </span>
          </div>
        ))}

        {/* List item model */}
        <Modal
          open={this.state.selectedData != null}
          onClose={() => this.setState({ selectedData: null })}
        >
          <Modal.Header>
            <Modal.Title>Display list item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Active Lead</strong>
            <LeadListItem
              lead={this.state.selectedData?.lead}
              onHideLead={{}}
              key={this.state.selectedData?.lead?.id}
              onClick={() => {
                this.setState({ viewLead: this.state.selectedData?.lead });
              }}
              hashTags={this.state.hashTags}
            />

            <br />
            <strong>Converted / Old / Reference / Similar Lead</strong>
            <LeadListItem
              lead={this.state.selectedData?.converted_lead}
              onHideLead={{}}
              key={this.state.selectedData?.converted_lead?.id}
              onClick={() => {
                this.setState({
                  viewLead: this.state.selectedData?.converted_lead,
                });
              }}
              hashTags={this.state.hashTags}
            />

            <br />
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={handleClose} appearance="primary">
              Ok
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button> */}
          </Modal.Footer>
        </Modal>

        {/* Lead display modal */}

        {this.state.viewLead != null && (
          <Drawer
            open={this.state.viewLead != null}
            onClose={() => this.setState({ viewLead: null })}
            placement="bottom"
            size="full"
          >
            <Drawer.Header>
              <Drawer.Title>
                View Lead - {this.state.viewLead?.shop_name}
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <HomeLeadView
                lead={this.state.viewLead}
                back={() => this.setState({ viewLead: null })}
                currentLocation={this.props.currentLocation}
                sessionCheck={this.props.sessionCheck}
                setView={(selectedLead) => this.setState({ selectedLead })}
                routes={this.state.routes}
                hashTags={this.state.hashTags}
                loadLeads={() => {}}
              />
            </Drawer.Body>
          </Drawer>
        )}
      </div>
    );
  }
}

export default DataManipulation;
