import Cookies from "js-cookie";
import moment from "moment";
import React, { Component } from "react";
import {
  Avatar,
  CheckPicker,
  DateRangePicker,
  Divider,
  Drawer,
  InputPicker,
  Button,
  Stack,
  Toggle,
} from "rsuite";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import constants, { getUserImage } from "../constants";
import { loadUsers } from "../Loaders/GeneralLoder";
import CustomLoader from "../Widgets/CustomLoader";
import {
  calculateDaysWithoutSundays,
  getProgressColorCombo,
} from "../Helpers/Utilities";

const filterTypes = [
  "customers",
  "sales",
  "sales_returns",
  "payments",
  "lead_spots",
  "lead_followups",
  "customer_followups",
  "lead_followups_confirmed",
];

var filterTypeLabels = {
  customers: "Customers",
  sales: "Sales",
  sales_returns: "Returns",
  payments: "Payments",
  lead_spots: "Leads",
  lead_followups: "Lead Follow",
  customer_followups: "Customer Follow",
  lead_followups_confirmed: "Confirmed Visits",
};

const sortTypes = [
  "customers",
  "sales",
  "sales_returns",
  "lead_spots",
  "own_lead_followups",
  "customer_followups",
];

const colors = [
  "#8884d8", // Purple
  "#82ca9d", // Green
  "#ffc658", // Yellow
  "#ff8042", // Orange
  "#8dd1e1", // Light Blue
  "#a4de6c", // Lime Green
  "#d0ed57", // Light Yellow
  "#ff7300", // Dark Orange
];

class TeamProgress extends Component {
  state = {
    date: [new Date(), new Date()],
    users: [],
    selectedUsers: [],
    data: [],
    filters: [],
    sortType: sortTypes[1],
    autoSort: true,
    loading: false,
    drawer: false,
  };

  setLoading = (loading = true) => this.setState({ loading });

  componentDidMount = () => {
    this.setLoading();
    loadUsers((users) =>
      this.setState({ users }, () => this.setLoading(false))
    );
  };

  loadData = () => {
    var { date, selectedUsers } = this.state;
    if (selectedUsers.length > 0) {
      console.log("loading data");
      this.setLoading();
      const startDate = moment(date[0]).format("YYYY-MM-DD");
      const endDate = moment(date[1]).format("YYYY-MM-DD");

      // Construct the request body with the parameters
      const requestBody = {
        startDate: startDate,
        endDate: endDate,
        users: selectedUsers,
      };

      fetch(constants.url + "user-progress", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(requestBody), // Send the parameters in the request body
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.setState({ data });
          this.setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  transformData = () => {
    const { data, filters, date } = this.state;

    const startDate = moment(date[0]);
    const endDate = moment(date[1]);

    // Check if the date gap is within 7 days
    const isDaily = endDate.diff(startDate, "days") <= 7;

    const aggregatedData = {};

    data.forEach((user) => {
      filters.forEach((filter) => {
        const childItems = user[filter] || [];

        childItems.forEach((item) => {
          // Use `created_at` for grouping
          const dateMoment = moment(item.created_at);
          const groupKey = isDaily
            ? dateMoment.format("YYYY-MM-DD") // Daily format
            : this.getWeekLabel(dateMoment); // Weekly label format

          if (!aggregatedData[groupKey]) {
            aggregatedData[groupKey] = {
              period: groupKey,
              ...filters.reduce((acc, f) => ({ ...acc, [f]: 0 }), {}),
            };
          }

          // Handle special cases
          if (filter === "payments") {
            aggregatedData[groupKey][filter] += parseFloat(item.amount || 0); // Sum payments
          } else if (filter === "lead_followups_confirmed") {
            if (item.event_type === "Direct Visit (Confirmed)") {
              aggregatedData[groupKey][filter] += 1; // Count confirmed visits
            }
          } else {
            aggregatedData[groupKey][filter] += 1; // Default: count items
          }
        });
      });
    });

    return Object.values(aggregatedData); // Convert object to array for Recharts
  };

  // Helper function to format weekly labels
  getWeekLabel = (dateMoment) => {
    const weekOfMonth = Math.ceil(dateMoment.date() / 7); // Calculate the week number in the month
    const monthName = dateMoment.format("MMM"); // Get the month name (e.g., "Dec")
    const weekSuffix = ["1st", "2nd", "3rd", "4th", "5th"][weekOfMonth - 1]; // Map week number to suffix

    return `${monthName} ${weekSuffix}`;
  };

  render() {
    const { filters, autoSort, sortType, data, date } = this.state;

    // Calculate the sum of selected filters for sorting
    const calculateFilterSum = (user) =>
      filters.reduce((sum, filter) => {
        if (filter === "payments") {
          // Handle special case for payments summing amounts
          return (
            sum +
            (user?.payments?.reduce((a, b) => a + parseFloat(b.amount), 0) || 0)
          );
        }
        return sum + (user?.[filter]?.length || 0); // Default to length for other filters
      }, 0);

    const sortedData = data.sort((a, b) => {
      if (autoSort) {
        return calculateFilterSum(b) - calculateFilterSum(a);
      } else {
        return (b[sortType]?.length || 0) - (a[sortType]?.length || 0);
      }
    });

    const chartData = this.transformData();

    const daysOfProgress =
      date && date[0] && date[1]
        ? calculateDaysWithoutSundays(date[0], date[1])
        : 1; // Default to 1 for a single day

    return (
      <div>
        <CustomLoader full text="Loading data.." loading={this.state.loading} />
        <Divider>
          Team Progress
          {daysOfProgress > 1 ? ` (${daysOfProgress} days)` : " (Single Day)"}
        </Divider>
        <div style={{ maxWidth: "90vw !important", overflow: "hidden" }}>
          <DateRangePicker
            placeholder="Select Dates"
            onChange={(date) => this.setState({ date })}
            placement="auto"
            value={this.state.date}
            size="sm"
            showOneCalendar
            block
          />
          <CheckPicker
            size="sm"
            block
            label="Filters"
            data={filterTypes.map((filter) => ({
              label: filter,
              value: filter,
            }))}
            loading={this.state.loading}
            value={this.state.filters}
            onChange={(filters) => {
              this.setState({ filters });
            }}
          />

          <CheckPicker
            size="sm"
            block
            label="Users"
            data={this.state.users.map((user) => ({
              label: user.name,
              value: user.id,
            }))}
            loading={this.state.loading}
            value={this.state.selectedUsers}
            onChange={(selectedUsers) => {
              this.setState({ selectedUsers });
            }}
            onExited={this.loadData}
          />

          <Divider />
          <span>Auto-Sort: </span>
          <Toggle
            checked={this.state.autoSort}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(value) => this.setState({ autoSort: value })}
          />
          <Divider />

          <InputPicker
            block
            value={this.state.sortType}
            onChange={(sortType) => this.setState({ sortType })}
            data={sortTypes.map((sort) => ({
              label: `Sort by ${sort}`,
              value: sort,
            }))}
            disabled={this.state.autoSort}
          />
        </div>

        <br />

        {sortedData.map((user, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: index === 0 ? "#fef9e7" : "#ffffff",
              borderRadius: "8px",
              padding: "10px",
              marginBottom: "8px",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              border: "1px solid #eaeaea",
            }}
          >
            <img
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                border: "2px solid #5dade2",
                objectFit: "cover",
              }}
              src={getUserImage(user)}
              alt={user.name}
            />

            <div style={{ marginLeft: "10px", flex: 1 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "6px",
                }}
              >
                <strong style={{ fontSize: "16px", color: "#34495e" }}>
                  {user.name}
                </strong>
                {index === 0 && (
                  <span
                    style={{
                      backgroundColor: "#ffdd59",
                      color: "#34495e",
                      padding: "2px 6px",
                      borderRadius: "8px",
                      fontSize: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Top Performer
                  </span>
                )}
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(80px, 1fr))",
                  gap: "5px",
                  fontSize: "12px",
                  color: "#7f8c8d",
                }}
              >
                {filters.map((filter) => {
                  let count = 0;
                  if (filter === "payments") {
                    count = user?.payments?.reduce(
                      (sum, payment) => sum + parseFloat(payment.amount),
                      0
                    );
                  } else if (filter === "lead_followups_confirmed") {
                    count =
                      user?.own_lead_followups?.filter(
                        (lf) => lf.event_type === "Direct Visit (Confirmed)"
                      ).length ?? 0;
                  } else {
                    const filterKey =
                      filter === "lead_followups"
                        ? "own_lead_followups"
                        : filter;
                    count = user[filterKey]?.length || 0;
                  }

                  return (
                    <div
                      key={filter}
                      style={{
                        padding: "2px",
                        backgroundColor:
                          filter == "customers"
                            ? getProgressColorCombo(count / daysOfProgress, 7)
                                ?.bg
                            : "#f8f9fa",
                        borderRadius: "4px",
                        textAlign: "center",
                        border: "1px solid #e0e0e0",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "700",
                          color:
                            filter == "customers"
                              ? getProgressColorCombo(count / daysOfProgress, 7)
                                  ?.text
                              : "#2c3e50",
                          display: "block",
                          fontSize: "14px",
                        }}
                      >
                        {count}
                      </span>
                      <span
                        style={{
                          color:
                            filter == "customers"
                              ? getProgressColorCombo(count / daysOfProgress, 7)
                                  ?.text
                              : "#7f8c8d",
                          fontSize: "10px",
                        }}
                      >
                        {filterTypeLabels[filter]}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}

        <hr />
        <Button
          onClick={() => this.setState({ drawer: true })}
          appearance="primary"
          color="yellow"
        >
          Visualize Data
        </Button>
        <Drawer
          open={this.state.drawer}
          onClose={() => this.setState({ drawer: false })}
          placement="bottom"
          size="full"
        >
          <Drawer.Header>
            <Drawer.Title>Visualized Team Progress</Drawer.Title>
            <Drawer.Actions></Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={this.transformData()} width={600} height={300}>
                <CartesianGrid strokeDasharray="3 3" />{" "}
                {/* Adds horizontal and vertical grid lines */}
                <XAxis dataKey="period" />
                <YAxis />
                <Tooltip />
                <Legend />
                {filters.map((filter, index) => (
                  <Line
                    key={filter}
                    type="monotone"
                    dataKey={filter}
                    stroke={colors[index % colors.length]} // Use dynamic colors
                    activeDot={{ r: 8 }}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Drawer.Body>
        </Drawer>
      </div>
    );
  }
}

export default TeamProgress;
