import React, { Component } from "react";
import TravelDistance from "../TravelDistance";

import {
  GoogleMap,
  Marker,
  MarkerF,
  Polyline,
  PolylineF,
  Polygon,
  PolygonF,
  Circle,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Divider, Modal, Button, Checkbox, Toggle } from "rsuite";
import constants, {
  alertError,
  alertInfo,
  haversineDistance,
} from "../../../../constants";
import Cookies from "js-cookie";

const containerStyle = {
  width: "100%",
  height: "470px",
};

class UserLocations extends Component {
  state = {
    selectedNode: null,
    selectedNodeIndex: null,
    locationEditModal: false,
    loading: false,
    showMarkers: false,
    filterCloseLocations: false,
    showCustomers: false,
    showEvents: false,
    showPayments: false,
    showReplacements: false,
    showRoutes: false,
    editNodes: false,
  };

  selectNode = (selectedNode = null, selectedNodeIndex = null) => {
    this.setState({ selectedNode, selectedNodeIndex });
    this.locationEditModal();
  };

  locationEditModal = (locationEditModal = true) => {
    this.setState({ locationEditModal });
  };

  checkIsFinite = (value, defaultValue = 100) => {
    return isFinite(value) ? value : defaultValue;
  };

  deleteNode = () => {
    this.setState({ loading: true });
    fetch(
      `${constants.url}delete-location-node/${this.state.selectedNode?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ selectedNode: null, loading: false });
        this.locationEditModal(false);
        this.props.deleteOne("user_locations", this.state.selectedNodeIndex);
        // this.props.refresh();
        window.AndroidInterface.showToast("Deleted location node");
      })
      .catch((error) => {
        console.error(error);
        this.setState({ selectedNode: null, loading: false });
        this.locationEditModal(false);
      });
  };

  deleteNodes = (list) => {
    this.setState({ loading: true });

    // Extract IDs from the list
    const ids = list.map((node) => node.id);

    fetch(`${constants.url}delete-location-nodes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify({ ids }), // Send the IDs as a JSON payload
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ loading: false });
        if (data.message) {
          alertInfo(data.message);
          // Optional: Refresh the data after deletion
          this.props.refresh();
        } else {
          alertError(data.error || "Failed to delete nodes!");
        }
      })
      .catch((error) => {
        console.error(error);
        alertError("Failed to delete nodes!");
        this.setState({ loading: false });
      });
  };

  updateNode = (new_location, node_id) => {
    console.log("updating node id " + node_id);
    this.setState({ loading: true });
    fetch(`${constants.url}update-location-node/${node_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify({ new_location }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ selectedNode: null, loading: false });
        this.locationEditModal(false);
        this.props.deleteOne("user_locations", this.state.selectedNodeIndex);
        // this.props.refresh();
        window.AndroidInterface.showToast("Deleted location node");
      })
      .catch((error) => {
        console.error(error);
        this.setState({ selectedNode: null, loading: false });
        this.locationEditModal(false);
      });
  };

  filterNodes = (returnUnwanted = false) => {
    var threshold = 60;
    const locations = this.props.data.user_locations;

    // Return all locations if filtering is not enabled
    if (!this.state.filterCloseLocations) {
      return locations;
    }

    if (!Array.isArray(locations) || locations.length === 0) return [];

    // Sort the locations by updateTime
    const sortedLocations = locations.sort(
      (a, b) => new Date(a.updateTime) - new Date(b.updateTime)
    );

    const filteredLocations = [sortedLocations[0]]; // Nodes to keep
    const unwantedLocations = []; // Nodes to delete

    for (let i = 1; i < sortedLocations.length; i++) {
      const lastLocation = filteredLocations[filteredLocations.length - 1];
      const currentLocation = sortedLocations[i];

      const distance = haversineDistance(
        lastLocation.geolocation,
        currentLocation.geolocation
      );

      if (distance < threshold) {
        unwantedLocations.push(currentLocation);
      } else {
        filteredLocations.push(currentLocation);
      }
    }

    // Return based on the returnUnwanted flag
    return returnUnwanted ? unwantedLocations : filteredLocations;
  };

  render() {
    var { data } = this.props;
    var empType = data.active_employment?.employment_type;

    var { selectedNode } = this.state;

    return (
      <div>
        <Divider>Distance Calculator</Divider>
        <TravelDistance
          sessionCheck={this.props.sessionCheck}
          empType={data.active_employment?.employment_type}
          locations={this.filterNodes()}
          customerPercent={this.checkIsFinite(
            (data.customers.length / (empType?.min_customers ?? 0)) * 100
          )}
          salesValuePercent={this.checkIsFinite(
            (this.props.salesValue / empType?.min_sales_value) * 100
          )}
          salesVolumePercent={this.checkIsFinite(
            (this.props.salesVolume / empType?.min_sales_volume) * 100
          )}
        />
        <Divider>Moves ({this.filterNodes().length})</Divider>
        {data?.user_locations?.length != 0 ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={this.filterNodes()[0].geolocation}
            zoom={12}
          >
            {/* Route polygons */}
            {this.state.showRoutes &&
              data.routes.map((route, index) => {
                if (route?.cord1?.length > 2) {
                  return (
                    <PolygonF
                      key={index}
                      options={{
                        paths: JSON.parse(route?.cord1).map((coord) => ({
                          lat: parseFloat(coord.lat),
                          lng: parseFloat(coord.lng),
                        })),
                        strokeColor: "#1abc9c",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillOpacity: 0,
                      }}
                    />
                  );
                }
              })}

            <PolylineF
              path={this.filterNodes().map((location) => ({
                lat: location.geolocation.lat,
                lng: location.geolocation.lng,
              }))}
              options={{
                strokeColor: "#2ecc71",
                strokeOpacity: 1,
                strokeWeight: 3,
              }}
            />

            <MarkerF
              position={
                this.filterNodes()[this.filterNodes().length - 1]?.geolocation
              }
              label={data.name}
            />

            {this.state.showMarkers &&
              this.filterNodes().map((location, index) => (
                <MarkerF
                  position={location.geolocation}
                  label={index + ""}
                  onClick={() => this.selectNode(location, index)}
                  draggable={this.state.editNodes}
                  onDragEnd={(event) => {
                    const newLocation = event.latLng;
                    console.log(
                      "changing user node - " + JSON.stringify(newLocation)
                    );
                    this.updateNode(newLocation, location.id);
                  }}
                />
              ))}

            {this.state.showCustomers &&
              data.customers.map((customer, index) => (
                <MarkerF
                  icon={{
                    url: constants.marker_green,
                    scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                  }}
                  position={JSON.parse(customer.geo_location)}
                  label={customer.shop_name}
                />
              ))}

            {/* Showing customers from PAYMENTS */}
            {this.state.showPayments &&
              data.payments.map((payment, index) => (
                <MarkerF
                  icon={{
                    url: constants.marker_green,
                    scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                  }}
                  position={JSON.parse(payment?.sale?.customer?.geo_location)}
                  label={`PAY: ${payment?.sale?.customer?.shop_name}`}
                />
              ))}

            {/* Showing customers from SALES RETURN REPLACEMENT */}
            {this.state.showReplacements &&
              data.sales_returns_replaced.map((replace, index) => (
                <MarkerF
                  icon={{
                    url: constants.marker_green,
                    scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                  }}
                  position={JSON.parse(replace?.customer?.geo_location)}
                  label={`REPLACE: ${replace?.customer?.shop_name}`}
                />
              ))}

            {this.state.showEvents &&
              data.assigned_lead_followups.map((followup, index) => {
                const geolocation = followup?.lead_spot?.geolocation;

                if (geolocation) {
                  try {
                    const position = JSON.parse(geolocation);
                    return (
                      <MarkerF
                        key={index} // added key to the mapped elements
                        icon={{
                          url: constants.marker_yellow,
                          scaledSize: new window.google.maps.Size(32, 32),
                        }}
                        position={position}
                        label={followup.lead_spot.shop_name}
                      />
                    );
                  } catch (e) {
                    console.error("Invalid JSON in geolocation:", geolocation);
                    return null;
                  }
                }

                return null;
              })}
          </GoogleMap>
        ) : (
          <strong>No user locations</strong>
        )}
        <br />
        <Divider>Nodes Manage</Divider>
        <Toggle
          checkedChildren="Locations"
          unCheckedChildren="Show locations"
          checked={this.state.showMarkers}
          onChange={(showMarkers) => this.setState({ showMarkers })}
        />
        &nbsp;&nbsp;
        {this.state.showMarkers ? (
          <Toggle
            checkedChildren="Filter Locations"
            unCheckedChildren="Filter Locations"
            checked={this.state.filterCloseLocations}
            onChange={(filterCloseLocations) =>
              this.setState({ filterCloseLocations })
            }
          />
        ) : (
          ""
        )}
        &nbsp;&nbsp;
        {this.state.showMarkers && this.state.filterCloseLocations ? (
          <Button
            appearance="primary"
            color="red"
            disabled={this.state.loading}
            onClick={() => {
              var conf = window.confirm(
                `Are you sure to delete ${this.filterNodes(true).length} nodes?`
              );
              if (conf) {
                this.deleteNodes(this.filterNodes(true));
              }
            }}
          >
            DELETE {this.filterNodes(true).length} CLOSEST NODES
          </Button>
        ) : (
          ""
        )}
        <hr />
        <br />
        <Toggle
          checkedChildren="Customers"
          unCheckedChildren="Show Customers"
          checked={this.state.showCustomers}
          onChange={(showCustomers) => this.setState({ showCustomers })}
        />
        <Toggle
          checkedChildren="Events"
          unCheckedChildren="Show Events"
          checked={this.state.showEvents}
          onChange={(showEvents) => this.setState({ showEvents })}
        />
        <Toggle
          checkedChildren="Payments"
          unCheckedChildren="Show Payments"
          checked={this.state.showPayments}
          onChange={(showPayments) => this.setState({ showPayments })}
        />
        <Toggle
          checkedChildren="Replacements"
          unCheckedChildren="Show Replacements"
          checked={this.state.showReplacements}
          onChange={(showReplacements) => this.setState({ showReplacements })}
        />
        <Toggle
          checkedChildren="Routes"
          unCheckedChildren="Show Routes"
          checked={this.state.showRoutes}
          onChange={(showRoutes) => this.setState({ showRoutes })}
        />
        <Toggle
          checkedChildren="Edit Nodes"
          unCheckedChildren="Edit Nodes"
          checked={this.state.editNodes}
          onChange={(editNodes) => this.setState({ editNodes })}
        />
        <Modal
          open={this.state.locationEditModal}
          onClose={() => this.locationEditModal(false)}
        >
          <Modal.Header>
            <Modal.Title>Edit Location Node</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Node id#: {selectedNode?.id}</strong>
            <br />
            <strong>
              Date Time: {selectedNode?.created_at} | {selectedNode?.updateTime}
            </strong>
            <br />
            <strong>Location lat: {selectedNode?.geolocation?.lat}</strong>
            <br />
            <strong>Location lng: {selectedNode?.geolocation?.lng}</strong>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.deleteNode}
              appearance="primary"
              color="red"
              disabled={this.state.loading}
            >
              Delete Node
            </Button>
            <Button
              onClick={() => this.locationEditModal(false)}
              appearance="subtle"
              disabled={this.state.loading}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default UserLocations;
