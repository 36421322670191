import React, { Component } from "react";
import { Badge, Button, Checkbox, Tag } from "rsuite";
import PhoneFillIcon from "@rsuite/icons/PhoneFill";
import CloseIcon from "@rsuite/icons/Close";
import MemberIcon from "@rsuite/icons/Member";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import moment from "moment";
import constants, { shortenedText } from "../constants";
import Cookies from "js-cookie";
import { rateColors, rateTexts } from "./new-customer-form";
import { setFavCustomer } from "../Helpers/Utilities";
import CustomProgress from "../Widgets/CustomProgress";
import { TiMessageTyping } from "react-icons/ti";

class CustomerListItem extends Component {
  state = {
    showReturns: false,
  };

  render() {
    var { customer, favs } = this.props;
    return (
      <div
        className="flex-row"
        style={{
          borderBottom: "12px solid #ededed",
          paddingBottom: "8px",
          paddingTop: "8px",
          position: "relative",
        }}
      >
        {customer.image_path && (
          <img
            src={constants.imagePath + customer.image_path}
            style={{
              width: "100px",
              height: "50px",
              marginRight: "12px",
            }}
            data-action="zoom"
          />
        )}

        <div
          style={{
            textAlign: "left",
          }}
        >
          <strong style={{ fontSize: "18pt" }}>
            {customer.shop_name} (#{customer.id}){" "}
            {customer.latest_sales_returns &&
            customer.latest_sales_returns.length > 0 ? (
              <Badge
                onClick={() =>
                  this.setState({ showReturns: !this.state.showReturns })
                }
                color="red"
                content="Returns!"
              />
            ) : null}
          </strong>
          <br />
          <i>{customer.address}</i>
          <div className="flex-row">
            {customer.sales_latest && (
              <span>
                {customer.sales_latest.status == "delivery" ? (
                  <Tag size="sm" color="yellow">
                    Delivery -{" "}
                    {moment(customer.sales_latest.delivery_date).fromNow()}
                  </Tag>
                ) : (
                  <Tag size="sm" color="blue">
                    Sales - {moment(customer.sales_latest.created_at).fromNow()}{" "}
                    - ({customer.sales_latest.status})
                  </Tag>
                )}
              </span>
            )}
            {customer.followups_latest && (
              <Tag size="sm">
                {customer?.followups_latest?.mode == "Phone" ? (
                  <PhoneFillIcon />
                ) : (
                  <MemberIcon />
                )}
                &nbsp;
                {shortenedText(customer.followups_latest.remarks)}
                {" - "}
                {moment(customer.followups_latest.created_at).fromNow()}
              </Tag>
            )}
          </div>
          {this.props.sales?.length > 0 ? (
            <div>
              {this.props.sales?.map((sale) => (
                <span style={{ marginLeft: "12px" }}>
                  <br />
                  <Tag
                    size="sm"
                    color={
                      sale.status == "complete"
                        ? "green"
                        : sale.status == "cancelled"
                        ? "red"
                        : sale.status == "delivery"
                        ? "orange"
                        : "blue"
                    }
                  >
                    #{sale.id} - {sale.status}{" "}
                    {sale.status == "delivery"
                      ? moment(sale.delivery_date).fromNow()
                      : ""}
                  </Tag>

                  {sale?.notes?.length > 0 ? (
                    <div
                      style={{
                        borderLeft: "4px solid gray",
                        paddingLeft: "6px",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      Sale Notes (OLD Form): {sale.notes ?? "No Notes"}
                    </div>
                  ) : (
                    ""
                  )}
                  <br />
                  {sale?.tracks
                    ?.filter((t) => t.type == "Notes Added")
                    .map((track) => (
                      <div
                        style={{
                          padding: "4px",
                          border: "1px dotted #ededed",
                          marginBottom: "4px",
                        }}
                      >
                        <TiMessageTyping /> &nbsp; {track.remarks}
                      </div>
                    ))}

                  <br />
                  {sale.items?.map((item) => (
                    <span>
                      {item.quantity} x {item?.product?.name}
                      <br />
                    </span>
                  ))}
                  <hr />
                </span>
              ))}
            </div>
          ) : (
            ""
          )}

          {this.state.showReturns ? (
            <div>
              {customer.latest_sales_returns?.map((returns) => (
                <span>
                  <br />
                  <Tag color="red" size="sm">
                    Returns
                  </Tag>
                  <br />
                  {returns.quantity} x {returns.product.name}
                  <hr />
                </span>
              ))}
            </div>
          ) : (
            ""
          )}
          <Button appearance="link" onClick={this.props.selectCustomer}>
            View Customer
          </Button>
          <Checkbox
            checked={favs.indexOf(customer.id) !== -1}
            onChange={(value) =>
              setFavCustomer(customer, (favs) => this.props.onFavUpdate(favs))
            }
          >
            {" "}
            <CheckRoundIcon /> Favorite
          </Checkbox>
          <Tag size="sm">@{customer.created_by_name}</Tag>
          <br />
          {customer.followups_latest && (
            <CustomProgress progress={customer.followups_latest.response} />
          )}
        </div>
        <span
          style={{
            position: "absolute",
            top: "5px",
            right: "3px",
            fontSize: "3px !important !important",
            backgroundColor: "#ededed",
            borderRadius: "3.5px",
            padding: "3px",
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: "5px",
              height: "5px",
              borderRadius: "2.5px",
              backgroundColor: rateColors[customer.rating],
            }}
          ></span>{" "}
          &nbsp;
          {rateTexts[customer.rating]}
        </span>
      </div>
    );
  }
}

export default CustomerListItem;
