import React, { Component } from "react";
import {
  Button,
  Drawer,
  Loader,
  Modal,
  Nav,
  Navbar,
  Sidenav,
  Toggle,
  Affix,
  Tag,
  Stack,
  Avatar,
} from "rsuite";

import "../App.css";
import "../index.css";

import Geocode from "react-geocode";
import HomeIcon from "@rsuite/icons/legacy/Home";
import CreativeIcon from "@rsuite/icons/Creative";
import MenuIcon from "@rsuite/icons/Menu";
import TagIcon from "@rsuite/icons/Tag";
import AbTestIcon from "@rsuite/icons/AbTest";
import TaskIcon from "@rsuite/icons/Task";
import ExitIcon from "@rsuite/icons/Exit";
import GearIcon from "@rsuite/icons/Gear";
import UserInfoIcon from "@rsuite/icons/UserInfo";
import LocationIcon from "@rsuite/icons/Location";
import CreditCardMinusIcon from "@rsuite/icons/CreditCardMinus";
import LineChartIcon from "@rsuite/icons/LineChart";
import SentToUserIcon from "@rsuite/icons/SentToUser";
import GridIcon from "@rsuite/icons/Grid";
import BytedanceMicroappIcon from "@rsuite/icons/BytedanceMicroapp";

import "rsuite/Avatar/styles/index.css";
import "rsuite/AvatarGroup/styles/index.css";

import constants, {
  getCurrentDateTime,
  getUserImage,
  pushUrl,
  urlHas,
} from "../constants";
import Customers from "./sub-pages/mobile/customers";
import HomeLeads from "./sub-pages/mobile/home-leads";
import Cookies from "js-cookie";
import SalesHome from "./sub-pages/mobile/SalesHome";
import Candidates from "./Candidates";
import UserIndex from "./sub-pages/mobile/users/user-index";
import RouteManager from "./sub-pages/mobile/route-manager";
import FinanceIndex from "./sub-pages/mobile/expenses/finance-index";
import PurchaseIndex from "./sub-pages/mobile/purchase/purchase-index";
import RBPHome from "./rbp_home";
import Products from "./Products";
import ProductCategories from "./sub-pages/ProductCategories";
import StoreManager from "./sub-pages/StoreManager";
import Settings from "./Settings";
import CallLogs from "../Modules/CallLogs";
import Visits from "./Visits";
import QuickFinder from "../Modules/QuickFInder";
import Shops from "./shops/Shops";
import Dashboard from "./sub-pages/dashboard";
import Deliveries from "./Deliveries";
import TeamProgress from "./Progress";
import InventoryControl from "./InventoryControl";
import MyLocations from "../Widgets/MyLocation";
import { changePassword } from "../Helpers/Utilities";
import LeadingCRE from "../components/LeadingCRE";
import AccountsIndex from "./Accounts/AccountsIndex";
import DataManipulation from "./DataManipulation/DataManipulation";
import CVisits from "./CVisits";
import StackAssistant from "../Modules/GoogleAI/StackAssistant";

Geocode.setApiKey(constants.map_api);
Geocode.setLanguage("en");

class MobileHome extends Component {
  state = {
    drawer: false,
    activeTab: 1,
    currentLocation: null,
    initialLocation: null,
    lastSentLocation: null,
    currentAddress: null,
    loadAddressShoot: false,
    map: null,
    sessionCheck: null,
    loadingText: "Loggin in",
  };

  setMap = (map) => {
    this.setState({ map });
  };

  componentDidMount = () => {
    this.sessionCheck();
    // this.locateMe();
    // this.watchCurrentLocation();
    this.getAndroidLocation();

    setTimeout(() => {
      this.sendUserLocation(true);
    }, 5000);

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    if (urlHas("manage-str")) {
      this.setState({ activeTab: 11 });
    }
    if (urlHas("manage-shops")) {
      this.setState({ activeTab: 111 });
    }
    if (urlHas("manage-leads")) {
      this.setState({ activeTab: 122 });
    }

    if (urlHas("visits")) {
      this.setState({ activeTab: 3 });
    }
    if (urlHas("cvisits")) {
      this.setState({ activeTab: 344 });
    }
    if (urlHas("deliveries")) {
      this.setState({ activeTab: 333 });
    }
    if (urlHas("team-progress")) {
      this.setState({ activeTab: 112 });
    }
    if (urlHas("inventory-control")) {
      this.setState({ activeTab: 444 });
    }
    if (urlHas("accounts-control")) {
      this.setState({ activeTab: 445 });
    }
    if (urlHas("data-manipulation")) {
      this.setState({ activeTab: 446 });
    }
  };

  componentWillUnmount = () => {
    // Remove event listener when component is unmounted
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  };

  handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      // Page is visible again, restart the sendUserLocation function
      this.getAndroidLocation();
      this.sendUserLocation();
    }
  };

  sessionCheck = async () => {
    this.setState({ loadingText: "Checking session.." });
    const url = constants.url + "sessionCheck";
    const headers = {
      Authorization: "Bearer " + Cookies.get("u-token"),
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(url, { method: "POST", headers });
      const data = await response.json();

      if (data?.status === true) {
        this.setState({ sessionCheck: data });
      } else {
        this.setState({ loadingText: "Invalid session" });
        this.logout();
      }
    } catch (error) {
      // An error occurred during the fetch request
      // Perform logout or handle the error as needed
      this.setState({ loadingText: "Session check error!" });
      this.logout();
    }
  };

  haversineDistance = (coord1, coord2) => {
    const earthRadius = 6371000; // Radius of the Earth in meters

    const degToRad = (deg) => deg * (Math.PI / 180);

    const lat1 = degToRad(parseFloat(coord1.lat));
    const lon1 = degToRad(parseFloat(coord1.lng));
    const lat2 = degToRad(parseFloat(coord2.lat));
    const lon2 = degToRad(parseFloat(coord2.lng));

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c;
    // window.debugToast("haversine distance " + distance);
    return distance;
  };

  sendUserLocation = (first = false) => {
    if (this?.state?.sessionCheck?.role == "Admin") {
      return;
    }
    //set a last sent location to non null
    var lastSentLocation = this.state.lastSentLocation ?? null;

    if (lastSentLocation == null) {
      console.log("lastsentlocation was null so setting new one");

      if (this.state?.sessionCheck?.user_locations_today?.length != 0) {
        var locations_today = this.state?.sessionCheck?.user_locations_today;
        lastSentLocation =
          locations_today[locations_today.length - 1]?.geolocation;
      }
      this.setState({ lastSentLocation });
    }

    if (
      this.state.currentLocation != null &&
      (lastSentLocation == null ||
        this.haversineDistance(lastSentLocation, this.state.currentLocation) >
          200)
    ) {
      console.log(
        "either lastlocation null or haversine distance is morethan 20m"
      );
      const url = constants.url + "updateUserLocation";
      setTimeout(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${Cookies.get("u-token")}`);

        var formdata = new FormData();
        formdata.append(
          "geolocation",
          JSON.stringify(this.state.currentLocation)
        ); // Convert the object to a JSON string

        formdata.append("updateTime", getCurrentDateTime());

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };

        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            this.setState({ lastSentLocation: this.state.currentLocation });
            setTimeout(this.sendUserLocation, 500);
            console.log("user location sent");
            console.log(result);
            window.debugToast("SUL Done");
          })
          .catch((error) => this.sendUserLocation());
      }, 2000);
    } else {
      console.log(
        "SUL check fail, recalling, distance " +
          this.haversineDistance(lastSentLocation, this.state.currentLocation)
      );
      window.debugToast("SUL Fail, recalling");
      setTimeout(this.sendUserLocation, 2000);
    }
  };

  getAndroidLocation = () => {
    // Check if the device is running on Android
    if (window.isAndroid()) {
      const { lat, lng } = window.geolocation;

      // Check if lat is a non-null, non-"0", and non-"0.0" value
      if (lat != null && lat !== "0" && lat !== "0.0") {
        const currentLocation = {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        };
        this.setState({ currentLocation });

        if (this.state.initialLocation == null) {
          this.setState({ initialLocation: currentLocation });

          // Pan and zoom into the user's location after 3 seconds
          setTimeout(() => {
            if (this.state.map != null) {
              this.state.map.setCenter(currentLocation);
              this.state.map.setZoom(18);
            }
          }, 3000);
        }

        // Load address if not already loaded
        if (!this.state.loadAddressShoot) {
          this.setState({ loadAddressShoot: true });
          setTimeout(() => {
            this.loadAddress();
          }, 200);
        }
      }
    } else {
      // If not on Android, use the geolocation API
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.setState({ currentLocation }, () => {
            // console.log("set geolocation");
            if (this.state.initialLocation == null) {
              // First time load, call locateMe()
              this.locateMe();
            }
          });
        },
        (error) => console.log(error)
      );
    }

    // Recursively call getAndroidLocation() every 2 seconds
    setTimeout(() => {
      this.getAndroidLocation();
    }, 4000);
  };

  locateMe = (callMe = null) => {
    console.log(`locate me called`);
    if (!window.isAndroid() || window.geolocation.lat == null) {
      console.log(`locateMe condition pass`);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.setState({ currentLocation });
          if (this.state.map != null) {
            //pan and zoom into user's location
            this.state.map.setZoom(18);
            this.state.map.setCenter(currentLocation);
          }

          if (this.state.initialLocation == null) {
            //first time load..
            this.setState({ initialLocation: currentLocation });
          }

          this.loadAddress(callMe);
        },
        (error) => console.log(error)
      );
    } else {
      console.log(`locateMe condition failed`);
      this.loadAddress(callMe);
    }
  };

  loadAddress = (callMe = null) => {
    //getting current address ..
    console.log(`loadAddress called`);
    this.setState({ currentAddress: null });
    var { currentLocation } = this.state;
    Geocode.fromLatLng(currentLocation?.lat, currentLocation?.lng).then(
      (response) => {
        const currentAddress = response.results[0].formatted_address;
        console.log(currentAddress);
        this.setState({ currentAddress });

        if (this.state.map != null) {
          //pan and zoom into user's location
          this.state.map.setZoom(18);
          this.state.map.setCenter(currentLocation);
        }
        //execute call me
        if (callMe) {
          setTimeout(() => {
            callMe(currentAddress);
          }, 200);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  watchCurrentLocation = (callMe = null, centerMap = false) => {
    this.setState({ currentAddress: null });

    const watchPosition = () => {
      this.locationWatcher = navigator.geolocation.watchPosition(
        (position) => {
          var currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          console.log("watch position: " + JSON.stringify(currentLocation));
          this.setState({ currentLocation });

          if (this.state.initialLocation == null) {
            //first time load..
            this.setState({ initialLocation: currentLocation });
          }

          // if (centerMap) {
          //   if (this.state.map != null) {
          //     // pan and zoom into user's location
          //     this.state.map.setZoom(18);
          //     this.state.map.setCenter(currentLocation);
          //   } else {
          //     this.locateMe();
          //   }
          // }

          // Getting current address ..
          Geocode.fromLatLng(currentLocation.lat, currentLocation.lng).then(
            (response) => {
              const currentAddress = response.results[0].formatted_address;
              console.log(currentAddress);
              this.setState({ currentAddress });

              // Execute callMe
              if (callMe != null) {
                setTimeout(() => {
                  callMe(currentAddress);
                }, 200);
              }
            },
            (error) => {
              console.error(error);
              // Retry after a delay
              setTimeout(() => {
                watchPosition();
              }, 2000);
            }
          );
        },
        (error) => {
          console.log(error);
          // Retry after a delay
          setTimeout(() => {
            watchPosition();
          }, 2000);
        }
      );
    };

    // Initial call to start watching the position
    watchPosition();
  };

  focusTo = (marker) => {
    if (marker && this.state.map != null) {
      //pan and zoom into user's location
      this.state.map.setCenter(marker.position);
      this.state.map.setZoom(18);
    } else {
      this.locateMe();
    }
  };

  drawer = (drawer = true) => {
    this.setState({ drawer });
  };

  activeTab = (activeTab = 1, urlParam = null) => {
    this.setState({ activeTab });
    this.drawer(false);
    pushUrl(urlParam);
  };

  logout = async () => {
    console.log("Log out is called");
    const tokenVar = Cookies.get("u-token");
    this.setState({ loadingText: "Logging out" });

    window.localStorage.removeItem("u-token");
    window.localStorage.removeItem("username");
    window.localStorage.removeItem("email");

    const url = constants.url + "logout";
    const headers = {
      Authorization: "Bearer " + tokenVar,
      "Content-Type": "application/json",
    };
    try {
      const response = await fetch(url, { method: "POST", headers });
      const data = await response.json();
      // if (data?.status == true) {

      // }
    } catch (error) {
      // alert(`Error in logingout ${error}`);
    }

    this.setState({ loadingText: "Re-load, after log out.." });
    setTimeout(() => {
      window.location.reload();
    }, 500);

    return;
  };

  render() {
    var { sessionCheck } = this.state;
    if (sessionCheck == null) {
      return <Loader content={this.state.loadingText} size="lg" />;
    }
    return (
      <div>
        {constants.url.startsWith("http://localhost") && (
          <strong style={{ position: "fixed", top: "0", right: "0" }}>
            DEVELOPMENT MODE
          </strong>
        )}
        <Affix>
          <Navbar>
            <Navbar.Brand
              onClick={() => {
                window.location.reload(true);
                this.setState({ sessionCheck: null });
              }}
            >
              <img src={constants.logo} className="logo center" />
            </Navbar.Brand>
            <Nav>
              {/* <Nav.Item icon={<HomeIcon />}>Home</Nav.Item> */}
              <Nav.Item className="custom-top-nav-item">
                <LeadingCRE />
              </Nav.Item>
            </Nav>
            <Nav pullRight>
              {" "}
              <Nav.Item className="custom-top-nav-item">
                <MyLocations
                  sessionCheck={this.state.sessionCheck}
                  currentLocation={this.state.currentLocation}
                />
              </Nav.Item>
              <Nav.Item className="custom-top-nav-item">
                <QuickFinder
                  sessionCheck={this.state.sessionCheck}
                  currentLocation={this.state.currentLocation}
                />
              </Nav.Item>
              <Nav.Item className="custom-top-nav-item">
                <CallLogs
                  sessionCheck={this.state.sessionCheck}
                  currentLocation={this.state.currentLocation}
                />
              </Nav.Item>
              <Nav.Item icon={<MenuIcon />} onClick={this.drawer}></Nav.Item>
            </Nav>
          </Navbar>
        </Affix>
        {/* Menu drawer */}
        <Drawer
          open={this.state.drawer}
          onClose={() => this.drawer(false)}
          placement="left"
          size="xs"
        >
          <Drawer.Header>
            <Drawer.Title>
              <img src={constants.logo} className="logo center" />
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <Stack>
              <Avatar
                color="blue"
                bordered
                circle
                src={getUserImage(sessionCheck?.user)}
                style={{ marginRight: "8px" }}
                size="lg"
              />
              <div>
                <h5>{window.localStorage.getItem("username")}</h5>
                <span>{window.localStorage.getItem("email")}</span>
                <br />
                <Tag color="green">
                  Employment:
                  {sessionCheck?.active_employment?.employment_type?.name ??
                    "No employment assigned"}
                </Tag>
                <hr />
                <Button
                  size="sm"
                  onClick={() => changePassword(sessionCheck.user.id)}
                >
                  Reset Password
                </Button>
              </div>
            </Stack>
            <StackAssistant
              sessionCheck={this.state.sessionCheck}
              currentLocation={this.state.currentLocation}
            />

            <br />
            <br />
            <Sidenav>
              <Sidenav.Body>
                <Nav activeKey={`${this.state.activeTab}`}>
                  <Nav.Item
                    eventKey="1"
                    icon={<LineChartIcon />}
                    onClick={() => this.activeTab(1)}
                  >
                    Dashboard
                  </Nav.Item>

                  {sessionCheck.role == "Admin" && (
                    <Nav.Item
                      eventKey="112"
                      icon={<SentToUserIcon />}
                      onClick={() => this.activeTab(112, "team-progress")}
                    >
                      Team Progress
                    </Nav.Item>
                  )}

                  {sessionCheck.role == "Admin" && (
                    <Nav.Item
                      eventKey="101"
                      icon={<HomeIcon />}
                      onClick={() => this.activeTab(101)}
                    >
                      Home
                    </Nav.Item>
                  )}

                  {sessionCheck.role == "Admin" && (
                    <Nav.Item
                      eventKey="11"
                      icon={<HomeIcon />}
                      onClick={() => this.activeTab(11, "manage-str")}
                    >
                      Store Home
                    </Nav.Item>
                  )}
                  {sessionCheck.role == "Admin" && (
                    <Nav.Item
                      eventKey="111"
                      icon={<CreativeIcon />}
                      onClick={() => this.activeTab(111, "manage-shops")}
                    >
                      Customers (Shops)
                    </Nav.Item>
                  )}

                  <Nav.Item
                    eventKey="122"
                    icon={<CreativeIcon />}
                    onClick={() => this.activeTab(122, "manage-leads")}
                  >
                    Leads
                  </Nav.Item>

                  <Nav.Item
                    eventKey="2"
                    icon={<TagIcon />}
                    onClick={() => this.activeTab(2)}
                  >
                    Sales
                  </Nav.Item>

                  <Nav.Item
                    eventKey="3"
                    icon={<AbTestIcon />}
                    onClick={() => this.activeTab(3, "visits")}
                  >
                    Visits
                  </Nav.Item>

                  <Nav.Item
                    eventKey="344"
                    icon={<AbTestIcon />}
                    onClick={() => this.activeTab(344, "cvisits")}
                  >
                    C-Visits
                  </Nav.Item>

                  <Nav.Item
                    eventKey="333"
                    icon={<AbTestIcon />}
                    onClick={() => this.activeTab(333, "deliveries")}
                  >
                    Deliveries
                  </Nav.Item>

                  {sessionCheck.role == "Admin" && (
                    <Nav.Item
                      eventKey="4"
                      icon={<TaskIcon />}
                      onClick={() => this.activeTab(4)}
                    >
                      Products
                    </Nav.Item>
                  )}

                  {sessionCheck.role == "Admin" && (
                    <Nav.Item
                      eventKey="444"
                      icon={<GridIcon />}
                      onClick={() => this.activeTab(444, "inventory-control")}
                    >
                      Inventory Control
                    </Nav.Item>
                  )}

                  {sessionCheck.roles.includes("ACCOUNTS_MODULE") && (
                    <Nav.Item
                      eventKey="445"
                      icon={<GridIcon />}
                      onClick={() => this.activeTab(445, "accounts-control")}
                    >
                      Accounts Module
                    </Nav.Item>
                  )}

                  {sessionCheck.roles.includes("DATA_MANIPULATION_MODULE") && (
                    <Nav.Item
                      eventKey="446"
                      icon={<BytedanceMicroappIcon />}
                      onClick={() => this.activeTab(446, "data-manipulation")}
                    >
                      Data Manipulation
                    </Nav.Item>
                  )}

                  {sessionCheck.role == "Admin" && (
                    <Nav.Item
                      eventKey="5"
                      icon={<UserInfoIcon />}
                      onClick={() => this.activeTab(5)}
                    >
                      Users
                    </Nav.Item>
                  )}

                  {sessionCheck.role == "Admin" && (
                    <Nav.Item
                      eventKey="55"
                      icon={<CreditCardMinusIcon />}
                      onClick={() => this.activeTab(55)}
                    >
                      Finance
                    </Nav.Item>
                  )}

                  {/* {sessionCheck.role == "Admin" && (
                    <Nav.Item
                      eventKey="56"
                      icon={<CreditCardMinusIcon />}
                      onClick={() => this.activeTab(56)}
                    >
                      Purchase
                    </Nav.Item>
                  )} */}

                  {sessionCheck.role == "Admin" && (
                    <Nav.Item
                      eventKey="6"
                      icon={<UserInfoIcon />}
                      onClick={() => this.activeTab(6)}
                    >
                      Candidates
                    </Nav.Item>
                  )}
                  {sessionCheck.role == "Admin" && (
                    <Nav.Item
                      eventKey="7"
                      icon={<LocationIcon />}
                      onClick={() => this.activeTab(7)}
                    >
                      Route Manager
                    </Nav.Item>
                  )}

                  {sessionCheck.role == "Admin" && (
                    <Nav.Item
                      eventKey="88"
                      icon={<GearIcon />}
                      onClick={() => this.activeTab(88)}
                    >
                      Settings
                    </Nav.Item>
                  )}

                  {/* //tab 8 goes to rbphome component */}

                  <Nav.Item
                    eventKey="44"
                    icon={<ExitIcon />}
                    onClick={this.logout}
                  >
                    Logout
                  </Nav.Item>
                </Nav>
              </Sidenav.Body>
            </Sidenav>
          </Drawer.Body>
        </Drawer>

        {this.state.activeTab == 1 && (
          <Dashboard
            sessionCheck={this.state.sessionCheck}
            currentLocation={this.state.currentLocation}
          />
        )}

        {this.state.activeTab == 112 && (
          <TeamProgress
            sessionCheck={this.state.sessionCheck}
            currentLocation={this.state.currentLocation}
          />
        )}

        {this.state.activeTab == 101 &&
          this.state.sessionCheck.role != "RBP" && (
            <Customers
              setMap={this.setMap}
              map={this.state.map}
              currentLocation={this.state.currentLocation}
              currentAddress={this.state.currentAddress}
              locateMe={this.locateMe}
              focusTo={this.focusTo}
              sessionCheck={this.state.sessionCheck}
              initialLocation={this.state.initialLocation}
            />
          )}

        {this.state.activeTab == 11 && (
          <StoreManager
            sessionCheck={this.state.sessionCheck}
            currentLocation={this.state.currentLocation}
          />
        )}

        {this.state.activeTab == 111 && (
          <Shops
            sessionCheck={this.state.sessionCheck}
            currentLocation={this.state.currentLocation}
          />
        )}

        {this.state.activeTab == 122 && (
          <HomeLeads
            sessionCheck={this.state.sessionCheck}
            currentLocation={this.state.currentLocation}
          />
        )}

        {this.state.activeTab == 1 && this.state.sessionCheck.role == "RBP" && (
          <RBPHome
            setMap={this.setMap}
            map={this.state.map}
            currentLocation={this.state.currentLocation}
            currentAddress={this.state.currentAddress}
            locateMe={this.locateMe}
            focusTo={this.focusTo}
            sessionCheck={this.state.sessionCheck}
            initialLocation={this.state.initialLocation}
          />
        )}
        {this.state.activeTab == 2 && (
          <SalesHome
            currentLocation={this.state.currentLocation}
            sessionCheck={this.state.sessionCheck}
          />
        )}
        {this.state.activeTab == 3 && (
          <Visits
            currentLocation={this.state.currentLocation}
            sessionCheck={this.state.sessionCheck}
          />
        )}
        {this.state.activeTab == 344 && (
          <CVisits
            currentLocation={this.state.currentLocation}
            sessionCheck={this.state.sessionCheck}
          />
        )}
        {this.state.activeTab == 333 && (
          <Deliveries
            currentLocation={this.state.currentLocation}
            sessionCheck={this.state.sessionCheck}
          />
        )}
        {this.state.activeTab == 4 && <Products />}
        {this.state.activeTab == 444 && <InventoryControl />}
        {this.state.activeTab == 445 && (
          <AccountsIndex
            currentLocation={this.state.currentLocation}
            sessionCheck={this.state.sessionCheck}
          />
        )}
        {this.state.activeTab == 446 && (
          <DataManipulation
            currentLocation={this.state.currentLocation}
            sessionCheck={this.state.sessionCheck}
          />
        )}
        {this.state.activeTab == 5 && (
          <UserIndex
            currentLocation={this.state.currentLocation}
            sessionCheck={this.state.sessionCheck}
          />
        )}
        {this.state.activeTab == 55 && <FinanceIndex />}
        {this.state.activeTab == 56 && <PurchaseIndex />}
        {this.state.activeTab == 6 && <Candidates />}
        {this.state.activeTab == 7 && (
          <RouteManager initialLocation={this.state.initialLocation} />
        )}
        {this.state.activeTab == 88 && <Settings />}
      </div>
    );
  }
}

export default MobileHome;
