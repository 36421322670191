import Cookies from "js-cookie";
import moment from "moment";
import React, { Component } from "react";
import {
  Affix,
  Button,
  DatePicker,
  DateRangePicker,
  Divider,
  Input,
  InputGroup,
  InputNumber,
  Modal,
  Pagination,
  SelectPicker,
  Stack,
  Toggle,
} from "rsuite";
import constants, {
  alertError,
  alertInfo,
  getUserImage,
} from "../../constants";
import { copyToClipboard } from "../../Helpers/Utilities";
import { loadUsers } from "../../Loaders/GeneralLoder";
import CustomLoader from "../../Widgets/CustomLoader";
import "./accounts.css";
import SuperAccounts from "./SuperAccounts";
import TrashIcon from "@rsuite/icons/Trash";

class AccountsIndex extends Component {
  state = {
    accounts: [],
    loading: false,
    modal: false,
    data: {},
    autoClear: true,
    entries: [],
    date: [new Date(), new Date()],
    page: 1,
    pageLimit: 30,
  };

  loading = (loading = true) => this.setState({ loading });

  componentDidMount = () => {
    //load accounts
    this.loading();
    fetch(constants.url + "accounts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ accounts: data });
        this.loading(false);
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
      });

    loadUsers((users) => this.setState({ users }));
    this.loadEntries();
  };

  loadEntries = () => {
    this.loading();
    var { date } = this.state;
    const startDate = moment(date[0]).format("YYYY-MM-DD");
    const endDate = moment(date[1]).format("YYYY-MM-DD");

    // Construct the request body with the parameters
    const requestBody = {
      startDate: startDate,
      endDate: endDate,
    };

    fetch(constants.url + "acc-entries", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(requestBody), // Send the parameters in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ entries: data.entries });
        this.loading(false);
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
      });
  };

  changeData = (where, what) => {
    var { data } = this.state;
    data[where] = what;
    this.setState({ data });
  };

  clearData = () => {
    const newData = {
      remarks: "",
      in_id: "",
      out_id: "",
      amount: "",
      connected_user: "",
    };
    if (this.props.sessionCheck.roles.includes("ACCOUNTS_ENTRY_DATE")) {
      newData.date = new Date();
    }

    this.setState({ data: newData }, () => console.log(this.state.data));
  };

  createEntry = () => {
    var { data, autoClear } = this.state;
    if (data?.amount == 0) {
      alertError("Invalid amount");
      return;
    }

    if (!data.in_id || !data.out_id) {
      alertError("Account IN and OUT is mandatory");
      return;
    }

    var conf = window.confirm("Sure to create an accounts entry?");
    if (!conf) return;

    if (
      this.props.sessionCheck.roles.includes("ACCOUNTS_ENTRY_DATE") &&
      data.date
    ) {
      data.date = moment(data.date).format("YYYY-MM-DD");
    }

    this.loading();
    fetch(constants.url + `acc-entry`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resData) => {
        this.loading(false);
        // this.modal(false);
        // window.toast("Transaction created");
        alertInfo("Created Accounts Entry");
        var { entries } = this.state;
        entries.push(resData.entry);
        this.setState({ entries });
        if (this.state.autoClear) {
          this.clearData();
        }
      })
      .catch((error) => {
        alertError("Failed to created accounts entry");
        console.error(error);
        this.loading(false);
      });
  };

  deleteEntry = (id) => {
    var conf = window.confirm("Are you sure to delete " + id);
    if (!conf) {
      return;
    }

    this.loading();
    fetch(constants.url + `acc-entry/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alertInfo("Entry deleted success");
        this.loadEntries();

        this.loading(false);
      })
      .catch((error) => {
        console.error(error);
        alertError("Unable to delete this entry " + error?.message);
        this.loading(false);
      });
  };

  render() {
    var { data, page, pageLimit } = this.state;
    const entries = this.state.entries.filter((entry) =>
      !this.state.filter
        ? true
        : Object.keys(this.state.data).some(
            (key) => this.state.data[key] !== ""
          ) // Check if any `data` key is not empty
        ? Object.keys(this.state.data).every(
            (key) =>
              this.state.data[key] === "" ||
              this.state.data[key] === null ||
              this.state.data[key]?.length == 0 ||
              key == "remarks"
                ? entry[key]?.toLowerCase()?.includes(this.state.data[key])
                : entry[key] === this.state.data[key] // Check matching for non-empty `data` keys
          )
        : true
    );
    return (
      <div>
        <Affix top={50}>
          <div style={{ background: "white" }}>
            <CustomLoader
              full
              text="Loading data.."
              loading={this.state.loading}
            />
            <h1>
              Accounts -{" "}
              <Button onClick={this.componentDidMount}>Refresh</Button> -{" "}
              <Button
                appearance="primary"
                onClick={() => this.setState({ modal: true, filter: false })}
                size="sm"
              >
                CREATE ENTRY
              </Button>
              <SuperAccounts sessionCheck={this.props.sessionCheck} />
            </h1>
            <Divider>Entries ({entries?.length ?? 0})</Divider>
            <InputGroup>
              <DateRangePicker
                placeholder="Select Dates"
                onChange={(date) => this.setState({ date }, this.loadEntries)}
                placement="auto"
                value={this.state.date}
                size="sm"
                showOneCalendar
                block
              />
              <Button
                onClick={() => this.setState({ filter: true, modal: true })}
                appearance="primary"
                color={this.state.filter ? "green" : "blue"}
              >
                Filter
              </Button>
            </InputGroup>
          </div>
        </Affix>

        {/* List entries */}
        <div className="entries-list">
          {entries
            .slice((page - 1) * pageLimit, page * pageLimit)
            .map((entry) => (
              <div className="entry-card" key={entry.id}>
                <div className="entry-header">
                  <h2
                    onClick={() =>
                      copyToClipboard(`Entry ID# ${entry.id}
                  Date: ${entry.date}
                  In Account: ${entry.in_account.name}
                  Out Account: ${entry.out_account.name}
                  Amount: $${entry.amount}
                  Remarks: ${entry.remarks || "No remarks"}
                  Created By: ${entry.creator.name}`)
                    }
                  >
                    Entry ID# {entry.id}
                  </h2>
                  <span className="entry-date">{entry.date}</span>
                </div>
                <div className="entry-details">
                  <p>
                    <strong>Out Account:</strong> {entry.out_account.name}
                  </p>
                  <p>
                    <strong>In Account:</strong> {entry.in_account.name}
                  </p>

                  <p>
                    <strong>Amount:</strong> ₹{entry.amount}
                  </p>
                  <p>
                    <strong>Remarks:</strong> {entry.remarks || "None"}
                  </p>
                </div>
                <div className="entry-meta">
                  <div className="user-info">
                    <img
                      src={getUserImage(entry.creator)}
                      alt="Creator"
                      className="user-image"
                    />
                    <p>
                      <strong>Created By:</strong> {entry.creator.name}{" "}
                      {moment(entry.created_at).fromNow()}
                      {entry.created_by === this.props.sessionCheck?.user?.id &&
                      moment(entry.created_at).isSame(moment(), "day") ? (
                        <Button
                          appearance="link"
                          color="red"
                          onClick={() => this.deleteEntry(entry.id)}
                        >
                          <TrashIcon /> Delete
                        </Button>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  {entry.connected_user ? (
                    <div className="user-info">
                      <img
                        src={getUserImage(entry.connected_user)}
                        alt="Connected User"
                        className="user-image"
                      />
                      <p>
                        <strong>Connected User:</strong>{" "}
                        {entry.connected_user?.name || "None"}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
        </div>

        <Pagination
          size="xs"
          total={entries.length}
          limit={pageLimit}
          activePage={page}
          onChangePage={(newPage) => {
            this.setState({ page: newPage });
            window.scrollTo(0, 0);
            // document.getElementById("scrollable").scrollTo(0, 0);
          }}
          prev
          last
          next
          first
          maxButtons={5}
        />

        {/* Create Entry and Filter Modal */}
        <Modal
          open={this.state.modal}
          onClose={() => this.setState({ modal: false })}
        >
          <Modal.Header>
            <Modal.Title>
              {this.state.filter ? "Filter Window" : "Accounts Entry"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.sessionCheck.roles.includes("ACCOUNTS_ENTRY_DATE") ? (
              <DatePicker
                value={data?.date}
                onChange={(value) => this.changeData("date", value)}
              />
            ) : (
              ""
            )}
            <br />
            <InputGroup>
              <InputGroup.Addon>OUT</InputGroup.Addon>
              <SelectPicker
                size="sm"
                // searchable={false}
                data={this.state.accounts
                  .filter((a) => a.out && a.acc_id !== data.in_id)
                  .map((ac) => ({ label: ac.account.name, value: ac.acc_id }))}
                value={data.out_id}
                onChange={(value) => this.changeData("out_id", value)}
              />
              <InputGroup.Addon>IN</InputGroup.Addon>
              <SelectPicker
                size="sm"
                // searchable={false}
                data={this.state.accounts
                  .filter((a) => a.in && a.acc_id !== data.out_id)
                  .map((ac) => ({ label: ac.account.name, value: ac.acc_id }))}
                value={data.in_id}
                onChange={(value) => this.changeData("in_id", value)}
              />
            </InputGroup>
            <Divider>Amount</Divider>
            <InputGroup>
              <InputGroup.Addon>₹</InputGroup.Addon>
              <InputNumber
                value={data.amount}
                onChange={(value) => this.changeData("amount", value)}
              />
            </InputGroup>
            <Divider>Remarks</Divider>
            <Input
              as="textarea"
              rows={3}
              placeholder="Remarks"
              value={data.remarks}
              onChange={(value) => this.changeData("remarks", value)}
            />

            {this.state.showAdvancedEntry ? (
              <div>
                <Divider>Connected User</Divider>
                <SelectPicker
                  size="sm"
                  // searchable={false}
                  data={this.state.users.map((user) => ({
                    label: user.name,
                    value: user.id,
                  }))}
                  value={data.connected_user}
                  onChange={(value) => this.changeData("connected_user", value)}
                />
              </div>
            ) : (
              <Button
                appearance="link"
                onClick={() => this.setState({ showAdvancedEntry: true })}
              >
                Show Advanced
              </Button>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!this.state.filter ? (
              <Stack justifyContent="space-between">
                <Stack>
                  <Button
                    onClick={this.clearData}
                    appearance="primary"
                    color="red"
                    size="sm"
                  >
                    CLEAR
                  </Button>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <Toggle
                    size="xs"
                    checked={this.state.autoClear}
                    checkedChildren="Auto Clear"
                    unCheckedChildren="Auto Clear"
                    onChange={(autoClear) => {
                      this.setState({ autoClear });
                    }}
                  />
                </Stack>
                <Button
                  onClick={this.createEntry}
                  appearance="primary"
                  disabled={this.state.loading}
                >
                  SAVE
                </Button>
              </Stack>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AccountsIndex;
