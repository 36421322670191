import React, { Component } from "react";
import { Input, Button, Stack } from "rsuite";

import { refineText } from "./AiHelper";

class InputAI extends Component {
  state = {
    loading: false,
  };

  loading = (loading = true) => this.setState({ loading });

  refineText = () => {
    this.loading();
    refineText(this.props?.value, (value) => {
      setTimeout(() => this.loading(false), 4000);
      if (value) {
        this.props.onChange(
          value.data?.candidates?.[0]?.content?.parts?.[0]?.text ??
            this.props?.value
        );
      }
    });
  };
  render() {
    if (this.props.buttonOnly) {
      return (
        <Button
          appearance="link"
          disabled={this.state.loading}
          onClick={this.refineText}
        >
          refine
        </Button>
      );
    }
    return (
      <div>
        <Stack justifyContent="space-between">
          <i>{this.props?.placeholder}</i>
          {this.props.disabled ? (
            ""
          ) : (
            <Button
              appearance="link"
              disabled={this.state.loading}
              onClick={this.refineText}
            >
              refine
            </Button>
          )}
        </Stack>
        <Input
          disabled={this.props?.disabled || this.state.loading}
          as={this.props?.as}
          rows={this.props?.rows}
          placeholder={this.props?.placeholder}
          value={this.props?.value}
          onChange={this.props?.onChange}
        />
      </div>
    );
  }
}

export default InputAI;
