import Cookies from "js-cookie";
import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Input,
  InputNumber,
  InputPicker,
  List,
  Modal,
} from "rsuite";
import constants, { alertError } from "../constants";
import InputAI from "../Modules/GoogleAI/InputAI";
import { modes } from "../Pages/sub-pages/mobile/customer-followup";
import CustomerListItem from "./CustomerListItem";

class AutoCVisits extends Component {
  state = {
    loading: false,
    modal: false,
    create: false,
    filterData: {
      route: this.props.route,
      saleOlderThan: 60,
      followupOlderThan: 20,
      directVisitOlderThan: 30,
    },
    filterResult: null,
    visitData: {
      route: this.props.route,
      mode: "",
      remarks: "",
    },
    customers: [],
    viewList: false,
    viewListFull: false,
  };

  changeVisitData = (where, what) => {
    var { visitData } = this.state;
    visitData[where] = what;
    this.setState({ visitData });
  };

  changeFilterData = (where, what) => {
    var { filterData } = this.state;
    filterData[where] = what;
    this.setState({ filterData });
  };

  check = () => {
    this.loading();
    var { filterData } = this.state;
    filterData.reqType = "CHECK";
    filterData.route = this.props.route;

    fetch(constants.url + "auto-c-visits", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(filterData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.loading(false);
        this.setState({ customers: data.customers });
        alert(
          `Total ${data?.count} eligible c-visits found in the given filter`
        );
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
        alertError("Failed.. check console window and update with IT Admin");
      });
  };

  create = () => {
    if (!this.state.create) {
      this.setState({ create: true });
    } else {
      this.loading();
      var { visitData, filterData } = this.state;

      visitData = {
        ...visitData,
        ...filterData,
      };

      if (visitData.mode?.length == 0 || visitData.remarks?.length < 3) {
        alertError("Invalid request data");
        return;
      }
      visitData.reqType = "CREATE";
      visitData.route = this.props.route;

      fetch(constants.url + "auto-c-visits", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(visitData),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.loading(false);
          alert(
            `Total ${data?.count} eligible c-visits found in the given filter`
          );
        })
        .catch((error) => {
          console.error(error);
          this.loading(false);
          alertError("Failed.. check console window and update with IT Admin");
        });
    }
  };

  loading = (loading = true) => this.setState({ loading });
  render() {
    var { visitData, filterData, viewListFull } = this.state;

    const customers = viewListFull
      ? this.state.customers
      : this.state.customers.slice(0, 30);

    return (
      <span>
        <Button onClick={() => this.setState({ modal: true })}>
          Auto C-Visits
        </Button>
        <Modal
          open={this.state.modal}
          onClose={() => this.setState({ modal: false })}
        >
          <Modal.Header>
            <Modal.Title>Auto C-Visits</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Route ID {this.props.route}</strong>
            <Divider>Filter Customers</Divider>
            <i>Last Sales Older Than Days</i>
            <InputNumber
              value={filterData.saleOlderThan}
              onChange={(value) =>
                this.changeFilterData("saleOlderThan", value)
              }
            />
            <br />

            <i>Last Followup Older Than Days</i>
            <InputNumber
              value={filterData.followupOlderThan}
              onChange={(value) =>
                this.changeFilterData("followupOlderThan", value)
              }
            />
            <br />
            <i>CVisits Older Than Days</i>
            <InputNumber
              value={filterData.directVisitOlderThan}
              onChange={(value) =>
                this.changeFilterData("directVisitOlderThan", value)
              }
            />
            <br />

            {this.state.create ? (
              <div>
                <Divider>Create C-Visits</Divider>
                <InputPicker
                  data={modes?.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  block
                  value={visitData.mode}
                  onChange={(value) => this.changeVisitData("mode", value)}
                />
                <br />
                <InputAI
                  as="textarea"
                  rows={3}
                  placeholder="Remarks"
                  value={visitData.remarks}
                  onChange={(value) => this.changeVisitData("remarks", value)}
                />
              </div>
            ) : (
              ""
            )}

            <br />

            <Divider>Customers match - {this.state.customers.length}</Divider>
            <Button
              appearance="link"
              onClick={() => this.setState({ viewList: !this.state.viewList })}
            >
              Toggle View List
            </Button>
            {this.state.viewList ? (
              <List hover>
                {customers.map((customer, index) => (
                  <List.Item>
                    <CustomerListItem
                      key={customer.id}
                      customer={customer}
                      favs={this.state?.favs ?? []}
                      onFavUpdate={(favs) => this.setState({ favs })}
                      selectCustomer={() => {
                        console.log(`selecting customer ${customer.id}`);
                        this.setState({ selectedCustomer: customer });
                      }}
                    />
                  </List.Item>
                ))}
              </List>
            ) : (
              ""
            )}

            {this.state.viewList ? (
              <Button
                appearance="link"
                onClick={() =>
                  this.setState({ viewListFull: !this.state.viewListFull })
                }
              >
                Toggle Show All (slower!!)
              </Button>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={this.state.loading}
              onClick={this.check}
              appearance="primary"
              color="yellow"
            >
              Check
            </Button>

            <Button
              disabled={this.state.loading}
              onClick={this.create}
              appearance="primary"
              color="green"
            >
              Create C-Visits
            </Button>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

export default AutoCVisits;
