import Cookies from "js-cookie";
import React, { Component } from "react";
import { Button, Divider, Drawer, Loader, Stack, Toggle } from "rsuite";
import constants, {
  alertError,
  alertInfo,
  alertInfoSilent,
  haversineDistance,
} from "../constants";
import { checkRoute } from "../Helpers/RouteHelper";
import {
  InfoWindow,
  Marker,
  Circle,
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  Polygon,
  Polyline,
  DirectionsRenderer,
  MarkerClusterer,
  PolygonF,
  PolylineF,
  MarkerClustererF,
  MarkerF,
} from "@react-google-maps/api";
import { updateLead } from "../Loaders/GeneralSaver";
import MarkerWithRotation from "../components/MarkerWithRotation";
import CustomerView from "../Pages/sub-pages/mobile/customer-view";
import HomeLeadView from "../Pages/sub-pages/mobile/home-lead-view";
import { getRouteZones } from "../Helpers/CoordinateHelper";

class RouteWalker extends Component {
  state = {
    loading: false,
    steps: [],
    walking: false,
    streetView: true,
    miniMap: null,
    selectedCustomer: null,
    selectedLead: null,
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  startWalking = () => {
    this.setState({ walking: true }, this.pushStep);
  };
  stopWalking = () => {
    this.setState({ walking: false });
  };

  componentDidUpdate = () => {};

  pushStep = () => {
    var { steps, walking } = this.state;
    var { selectedRoute, actualRoute } = this.props;
    if (selectedRoute?.id != actualRoute?.id) {
      alertError("You are out side of tours route", 1500);
    }
    var lastStep = steps.length > 0 ? steps[steps.length - 1] : null;
    var newStep = this.getCoordinate();
    if (lastStep) {
      if (haversineDistance(lastStep, newStep) > 40) {
        steps.push(newStep);
      }
    } else if (newStep != null) {
      steps.push(newStep);
    }
    this.setState({ steps });
    if (walking) {
      setTimeout(this.pushStep, 2000);
    }
    // if (this.state.miniMap && this.props.mapObject) {
    //   var pov = this.props.mapObject?.getStreetView()?.getPov();
    //   this.state.miniMap.setOptions({
    //     heading: pov?.heading || 0,
    //   });
    // }
  };

  clearWalking = () => {
    var conf = window.confirm("Sure to clear walked, steps?");
    if (!conf) {
      return;
    }
    this.setState({ steps: [] });
  };

  saveWalking = () => {
    var { steps, streetView } = this.state;
    var data = {
      cords: JSON.stringify(steps.concat(this.getCoordinate())),
      type: streetView ? "streetview" : "live",
      route_id: this.props.selectedRoute?.id,
    };
    this.setLoading();
    fetch(constants.url + "route-walks", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setLoading(false);
        this.props.onCreate();
        alertInfo("Route Walk created");
        this.setState({ steps: [] });
      })
      .catch((error) => {
        console.error(error);
        alertError("Failed to create route walk");
        this.setLoading(false);
      });
  };

  getCoordinate = () => {
    var { streetView } = this.state;
    var streetViewPosition = this.props?.mapObject
      ?.getStreetView()
      ?.getPosition();
    if (streetViewPosition && streetView) {
      const streetViewCoordinates = {
        lat: streetViewPosition.lat(),
        lng: streetViewPosition.lng(),
      };

      return streetViewCoordinates;
    }

    if (!streetView) {
      return this.props.currentLocation;
    }

    return null;
  };

  render() {
    var { steps, walking, selectedCustomer, selectedLead } = this.state;
    var { selectedRoute, nearestLead, nearestCustomer } = this.props;
    var currentRoute = checkRoute(this.getCoordinate(), this.props.routes);
    return (
      <div>
        {this.props.filterMapMiniMap && (
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "150px",
            }}
            zoom={19}
            center={this.getCoordinate()}
            onLoad={(miniMap) => {
              this.setState({ miniMap });
              window.miniMap = miniMap;
              miniMap.setOptions({ heading: 60 });
            }}
            onUnmount={() => this.setState({ miniMap: null })}
            mapTypeId="roadmap"
          >
            <Polygon
              options={{
                paths: JSON.parse(selectedRoute?.cord1).map((coord) => ({
                  lat: parseFloat(coord.lat),
                  lng: parseFloat(coord.lng),
                })),
                strokeColor: "#1abc9c",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillOpacity: 0,
              }}
            />

            <PolylineF
              path={getRouteZones(selectedRoute)?.verticalLine}
              options={{
                strokeColor: "#697987",
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
            <PolylineF
              path={getRouteZones(selectedRoute)?.horizontalLine}
              options={{
                strokeColor: "#697987",
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />

            {this.props.routeWalks.map((routeWalk, index) => (
              <Polyline
                key={index}
                path={JSON.parse(routeWalk?.cords)}
                options={{
                  strokeColor: "#00FF00",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            ))}

            {this.getCoordinate() && (
              <PolylineF
                key="route-walker-steps"
                path={steps.concat(this.getCoordinate())}
                options={{
                  strokeColor: "#0000FF",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            )}

            {this.props.nearestLeads?.map((lead, index) => (
              <MarkerF
                key={`lead-mini-${index}`}
                draggable={this.props.filterMapMiniMapEdit}
                onDragEnd={(event) => {
                  console.log(`lead edit drag end ${lead.id}`);
                  const newLocation = event.latLng;
                  lead.geolocation = JSON.stringify(newLocation);
                  updateLead(lead);
                }}
                position={JSON.parse(lead.geolocation)}
                label={{
                  text: "L",
                  fontSize: "10",
                }}
                onClick={() => {
                  alertInfoSilent(lead.shop_name, 3000, () =>
                    this.setState({ selectedLead: lead })
                  );
                  return false;
                }}
              />
            ))}

            {this.props.nearestCustomers.map((customer, index) => (
              <MarkerF
                key={`customer-mini-${index}`}
                position={JSON.parse(customer.geo_location)}
                label={{
                  text: "C",
                  fontSize: "10",
                }}
                onClick={() => {
                  alertInfoSilent(customer.shop_name, 3000, () =>
                    this.setState({ selectedCustomer: customer })
                  );
                  return false;
                }}
              />
            ))}

            <MarkerF
              position={this.getCoordinate()}
              icon={{
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 6, // Adjust the scale as needed
                fillColor: "#B0C4DE", // Light blue-gray color
                fillOpacity: 1, // Opacity of the fill color
                strokeColor: "#778899", // Gray color for stroke
                strokeOpacity: 1, // Opacity of the stroke color
                strokeWeight: 2, // Weight of the stroke
                rotation:
                  this.props.mapObject?.getStreetView()?.getPov()?.heading || 0,
              }}
            />
          </GoogleMap>
        )}

        <Divider>
          Steps {steps.length} - Route: {currentRoute?.route_name}
        </Divider>
        {this.state.loading ? (
          <Loader size="sm" />
        ) : (
          <Stack justifyContent="space-around">
            {!walking && (
              <Toggle
                checked={this.state.streetView}
                checkedChildren="StreetView"
                unCheckedChildren="Live"
                onChange={(value) => this.setState({ streetView: value })}
              />
            )}
            {walking ? (
              <Button
                size="sm"
                appearance="primary"
                color="red"
                onClick={this.stopWalking}
              >
                Stop
              </Button>
            ) : (
              <Button
                size="sm"
                appearance="primary"
                color={steps.length > 0 ? "yellow" : "green"}
                onClick={this.startWalking}
              >
                {steps.length > 0 ? "Continue" : "Start"}
              </Button>
            )}
            {steps.length > 0 && !walking && (
              <Button
                size="sm"
                appearance="primary"
                color="orange"
                onClick={this.clearWalking}
              >
                Clear
              </Button>
            )}
            {steps.length > 0 && !walking && (
              <Button
                size="sm"
                appearance="primary"
                color="blue"
                onClick={this.saveWalking}
              >
                Save
              </Button>
            )}
          </Stack>
        )}

        {/* Lead & Custoemr View Drawer */}
        {selectedCustomer != null || selectedLead != null ? (
          <Drawer
            placement="bottom"
            size="full"
            open={selectedCustomer != null || selectedLead != null}
            onClose={() =>
              this.setState({ selectedCustomer: null, selectedLead: null })
            }
          >
            <Drawer.Header>
              <Drawer.Title>
                View {selectedCustomer ? "Customer" : "Lead"}
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              {selectedCustomer ? (
                <CustomerView
                  data={selectedCustomer}
                  back={() => this.setState({ selectedCustomer: null })}
                  currentLocation={this.props.currentLocation}
                  sessionCheck={this.props.sessionCheck}
                />
              ) : selectedLead ? (
                <HomeLeadView
                  lead={selectedLead}
                  back={() => this.setState({ selectedLead: null })}
                  currentLocation={this.props.currentLocation}
                  sessionCheck={this.props.sessionCheck}
                  setView={(selectedLead) => this.setState({ selectedLead })}
                  loadLeads={() => {}}
                  routes={this.props.routes}
                />
              ) : (
                ""
              )}
            </Drawer.Body>
          </Drawer>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default RouteWalker;
