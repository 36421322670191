import React, { Component } from "react";
import {
  Button,
  CheckPicker,
  InputGroup,
  Input,
  Modal,
  Divider,
  Toggle,
  Drawer,
  DatePicker,
  Nav,
  Badge,
  Calendar,
  Tag,
  List,
  InputPicker,
  Stack,
  SelectPicker,
} from "rsuite";
import { loadRoutes, loadVisitData } from "../Loaders/GeneralLoder";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import FunnelIcon from "@rsuite/icons/Funnel";
import ListIcon from "@rsuite/icons/List";
import ExploreIcon from "@rsuite/icons/Explore";
import ReloadIcon from "@rsuite/icons/Reload";
import { MdOutlineGraphicEq } from "react-icons/md";
import { FaMapMarkedAlt } from "react-icons/fa";

import {
  InfoWindow,
  Marker,
  Circle,
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  Polygon,
  Polyline,
  DirectionsRenderer,
  MarkerClusterer,
  PolygonF,
  PolylineF,
  MarkerClustererF,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import Cookies from "js-cookie";
import constants, {
  alertError,
  alertInfoSilent,
  haversineDistance,
} from "../constants";
import CustomerView from "./sub-pages/mobile/customer-view";
import HomeLeadView from "./sub-pages/mobile/home-lead-view";
import moment from "moment";
import CustomLoader from "../Widgets/CustomLoader";
import LeadListItem from "../components/LeadListItem";
import {
  deepCopy,
  getFavCustomers,
  truncatedString,
} from "../Helpers/Utilities";
import SalesQtyList from "../Modules/SalesQtyList";
import CustomerListItem from "../components/CustomerListItem";
import AutoCVisits from "../components/AutoCVisits";
import { getNearestItems, getRouteZones } from "../Helpers/CoordinateHelper";

class CVisits extends Component {
  state = {
    routes: [],
    route: null,
    selectedCustomer: null,
    loading: false,
    cVisits: [],
    page: 1,
    pageLimit: 30,
  };

  loading = (loading = true) => this.setState({ loading });

  componentDidMount = () => {
    loadRoutes((routes) => this.setState({ routes }));
  };

  loadData = () => {
    if (!this.state.route) {
      return;
    }
    this.loading();
    fetch(constants.url + `c-visits/${this.state.route}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.loading(false);

        this.sortDataZoneBased(
          this.state.routes.find((r) => r.id == this.state.route),
          data
        );
      })
      .catch((error) => {
        this.loading(false);
        alertError("Unable to load c visits");
        this.loadData();
        console.error(error);
      });
  };

  sortDataZoneBased = (selectedRoute, data) => {
    const { verticalLine, horizontalLine } = getRouteZones(selectedRoute);

    if (verticalLine && horizontalLine) {
      const verticalLng = verticalLine[0]?.lng; // Longitude of vertical line
      const horizontalLat = horizontalLine[0]?.lat; // Latitude of horizontal line

      // Function to determine the zone for a given customer
      const getZone = (coords) => {
        if (!coords.lat || !coords.lng) return 0;

        if (coords.lat > horizontalLat && coords.lng < verticalLng) return 1; // Top-left
        if (coords.lat <= horizontalLat && coords.lng < verticalLng) return 2; // Bottom-left
        if (coords.lat > horizontalLat && coords.lng >= verticalLng) return 3; // Top-right
        if (coords.lat <= horizontalLat && coords.lng >= verticalLng) return 4; // Bottom-right

        return 0; // Default if no zone matches
      };

      // Sort cVisits by zone
      const sortedCVisits = data.cVisits.sort((a, b) => {
        const customerACoords = JSON.parse(a.geo_location || "{}");
        const customerBCoords = JSON.parse(b.geo_location || "{}");

        const zoneA = getZone(customerACoords);
        const zoneB = getZone(customerBCoords);

        return zoneA - zoneB; // Sort by zone number
      });

      // Update state or use sortedCVisits
      this.setState({ cVisits: sortedCVisits, page: 1 });
    }
  };

  render() {
    var { routes, route, loading, selectedCustomer, cVisits, page, pageLimit } =
      this.state;
    var selectedRoute = routes.find((r) => r.id == route) ?? null;

    return (
      <div>
        <CustomLoader full text="Loading CVisits." loading={loading} />
        <Stack
          style={{ width: "99vw", overflowX: "hidden" }}
          justifyContent="space-between"
        >
          <div style={{ width: "60vw" }}>
            <SelectPicker
              size="sm"
              placeholder="Select Route"
              data={routes.map((route) => ({
                label: route.route_name,
                value: route.id,
              }))}
              loading={loading}
              value={route}
              onChange={(route) => {
                this.setState({ route }, this.loadData);
              }}
            />
          </div>
          <Button
            appearance="primary"
            disabled={loading}
            onClick={this.loadData}
            size="md"
          >
            <ReloadIcon />
          </Button>
          {route && this.props.sessionCheck.role === "Admin" ? (
            <AutoCVisits route={route} />
          ) : (
            ""
          )}
          {/* <Button
            disabled={loading}
            size="xs"
            onClick={() => this.setState({ filterModal: true })}
          >
            <FunnelIcon />
          </Button> */}

          {/* <Button
            disabled={loading}
            onClick={() => this.setState({ followMe: !followMe })}
            appearance={followMe ? "primary" : "default"}
            color="green"
            size="xs"
          >
            <ExploreIcon />
          </Button> */}
        </Stack>

        <Divider>
          {page > 1 ? (
            <Button
              appearance="link"
              onClick={() => this.setState({ page: page - 1 })}
            >
              &lt;&lt; Previous
            </Button>
          ) : (
            ""
          )}
          <Tag
            onClick={() => {
              var newLimit = window.prompt(
                "Number of records per page:",
                pageLimit
              );

              // Check if newLimit is a valid number
              newLimit = Number(newLimit); // Convert to number

              if (!isNaN(newLimit) && newLimit > 0) {
                this.setState({ pageLimit: newLimit, page: 1 });
              } else {
                alert("Please enter a valid number greater than 0.");
              }
            }}
          >
            {cVisits.length}
          </Tag>
          CVisits
          {page < cVisits.length / pageLimit ? (
            <Button
              appearance="link"
              onClick={() => this.setState({ page: page + 1 })}
            >
              Next &gt;&gt;
            </Button>
          ) : (
            ""
          )}
        </Divider>

        {/* Map Mode */}

        <>
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "76vh",
            }}
            zoom={10}
            onLoad={(map) => {
              this.setState({ map });
              map.setCenter(this.props.currentLocation);
            }}
            onUnmount={() => this.setState({ map: null })}
            mapTypeId="roadmap"
          >
            {selectedRoute ? (
              <>
                <PolygonF
                  options={{
                    paths: JSON.parse(selectedRoute?.cord1).map((coord) => ({
                      lat: parseFloat(coord.lat),
                      lng: parseFloat(coord.lng),
                    })),
                    strokeColor: "#1abc9c",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillOpacity: 0,
                  }}
                />
                <PolylineF
                  path={getRouteZones(selectedRoute)?.verticalLine}
                  options={{
                    strokeColor: "#697987",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                  }}
                />
                <PolylineF
                  path={getRouteZones(selectedRoute)?.horizontalLine}
                  options={{
                    strokeColor: "#697987",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                  }}
                />
              </>
            ) : (
              ""
            )}

            {cVisits
              ?.slice((page - 1) * pageLimit, page * pageLimit)
              ?.map((customer, index) => (
                <MarkerF
                  key={`D${index}`}
                  position={JSON.parse(customer.geo_location)}
                  icon={{
                    url:
                      customer.latest_sales_returns?.length > 0
                        ? constants.marker_pink
                        : constants.marker_red,
                    scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                  }}
                  label={{
                    text: truncatedString(customer.shop_name),
                    color: "black",
                    fontSize: "8",
                  }}
                  onClick={() => {
                    setTimeout(() => {
                      if (this.state.clickedCustomer?.id !== customer.id) {
                        this.setState({ clickedCustomer: customer });
                      }
                    }, 100); // delay by 100ms
                  }}
                  onDblClick={() =>
                    (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                      JSON.parse(customer.geo_location).lat
                    },${
                      JSON.parse(customer.geo_location).lng
                    }&travelmode=motorcycle`)
                  }
                >
                  {/* Render InfoWindow only for the clickedCustomer */}
                  {this.state.clickedCustomer?.id === customer.id && (
                    <InfoWindowF
                      position={JSON.parse(customer.geo_location)} // Explicit position
                      onCloseClick={() =>
                        this.setState({ clickedCustomer: null })
                      }
                    >
                      <span style={{ padding: "4px" }}>
                        <CustomerListItem
                          key={customer.id}
                          customer={customer}
                          favs={this.state?.favs ?? []}
                          onFavUpdate={(favs) => this.setState({ favs })}
                          selectCustomer={() => {
                            this.setState({ selectedCustomer: customer });
                          }}
                        />
                        <br />
                        <Button
                          appearance="primary"
                          color="green"
                          onClick={() =>
                            (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                              JSON.parse(customer.geo_location).lat
                            },${
                              JSON.parse(customer.geo_location).lng
                            }&travelmode=motorcycle`)
                          }
                          size="xs"
                        >
                          NAVIGATE
                        </Button>
                        <br />
                      </span>
                    </InfoWindowF>
                  )}
                </MarkerF>
              ))}
            <MarkerF
              position={this.props.currentLocation}
              label={{
                text: "You",
                fontSize: "10",
              }}
            />
          </GoogleMap>
        </>

        {/*Customer View Drawer */}

        <Drawer
          placement="bottom"
          size="full"
          open={selectedCustomer != null}
          onClose={() => this.setState({ selectedCustomer: null })}
        >
          <Drawer.Header>
            <Drawer.Title>View {selectedCustomer?.shop_name}</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            {selectedCustomer ? (
              <CustomerView
                data={selectedCustomer}
                back={() => this.setState({ selectedCustomer: null })}
                currentLocation={this.props.currentLocation}
                sessionCheck={this.props.sessionCheck}
              />
            ) : (
              ""
            )}
          </Drawer.Body>
        </Drawer>
      </div>
    );
  }
}

export default CVisits;
