import Cookies from "js-cookie";
import constants, {
  alertError,
  alertInfo,
  alertInfoSilent,
  sqlDateTime,
} from "../constants";
import { confirm } from "../Helpers/Utilities";

export function createHashTag(name, onLoad) {
  fetch(constants.url + "hash-tags", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
    body: JSON.stringify({ name }),
  })
    .then((response) => response.json())
    .then((data) => {
      onLoad(data.hash_tags);
    })
    .catch((error) => {
      console.error(error);
      alertError("Unable to create  hashtag");
    });
}

export function updateLead(lead, onUpdate = null, needAlerts = true) {
  if (needAlerts) {
    var conf = window.confirm(`Sure to update lead ${lead.shop_name}`);
    if (!conf) {
      return;
    }
  }
  fetch(constants.url + "lead_spots/" + lead.id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
    body: JSON.stringify(lead),
  })
    .then((response) => response.json())
    .then((data) => {
      if (needAlerts) {
        alertInfoSilent(`Lead updated (${lead.shop_name})`);
      }
      if (onUpdate) {
        onUpdate(data);
      }
    })
    .catch((error) => {
      console.error(error);
      if (needAlerts) {
        alertError("Failed to update lead");
      }
    });
}

export function bulkCreateLeadFollowup(followup, leads, onCreate) {
  confirm("Are you sure to create these bulk followups?", () => {
    followup.event_date = sqlDateTime(followup.event_date);
    followup.event_done_at = sqlDateTime(followup.event_done_at);

    fetch(constants.url + "lead_followup-bulk-create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify({ followup, leads: leads.map((l) => l.id) }),
    })
      .then((response) => response.json())
      .then((data) => {
        onCreate();
        alertInfo("Created BULK Visits.");
      })
      .catch((error) => {
        console.error(error);
        alertError("Unable to create bulk followups");
      });
  });
}

export function createLead(data, onDone, onFail) {
  fetch(constants.url + "lead_spots", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("u-token")}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success == false) {
        alertError(data.message);
        onFail();
      } else {
        onDone(data.data);
      }
    })
    .catch((error) => {
      onFail();
    });
}
