import Cookies from "js-cookie";
import moment from "moment";
import React, { Component } from "react";
import {
  List,
  Loader,
  Panel,
  Stack,
  Button,
  ButtonGroup,
  Modal,
  InputPicker,
  InputNumber,
  Toggle,
  Input,
  Tag,
  Slider,
} from "rsuite";
import constants, {
  alertError,
  alertInfo,
  getCurrentDateTime,
} from "../../../constants";
import CustomProgress from "../../../Widgets/CustomProgress";

import InputAI from "../../../Modules/GoogleAI/InputAI";

const title = "Customer Followup";
export const modes = ["Phone", "Direct Visit", "Whatsapp", "Email", "Other"];

class CustomerFollowup extends Component {
  state = {
    followups: [],
    loading: true,
    modal: false,
    item: {},
  };

  setItem = (followup = null, modal = false) => {
    var { item } = this.state;
    if (followup != null) {
      item = followup;
    } else {
      item = {
        customer_id: this.props.customer.id,
        mode: "Phone",
        remarks: "",
        response: 60,
      };
    }
    this.setState({ item, modal });
  };

  changeItem = (where, what) => {
    var { item } = this.state;
    item[where] = what;
    this.setState({ item });
  };

  componentDidMount = () => {
    this.loadCustomerFollowups();
    this.setItem();
  };

  loadCustomerFollowups = () => {
    this.setState({ followups: null });
    fetch(
      constants.url +
        `customer_followups/by_customer/${this.props.customer.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ followups: data, loading: false });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  modal = (modal = true) => {
    this.setState({ modal });
    if (!modal) {
      this.setState({ item: {} });
    }
  };

  save = () => {
    this.setState({ loading: true });
    var data = this.state.item;

    data.time = getCurrentDateTime();

    var url =
      constants.url + "customer_followups" + (data.id ? `/${data.id}` : "");

    fetch(url, {
      method: data.id ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        alertInfo(`Followup ${data.id ? "updated" : "created"}`);
        this.setState({ loading: false });
        this.loadCustomerFollowups();
        this.setItem();
      })
      .catch((error) => {
        alertError(`Failed to ${data.id ? "update" : "create"}`);
        this.setState({ loading: false });
      });
  };

  render() {
    var { followups, item } = this.state;
    if (followups == null) {
      return <Loader content={`Loading ${title}..`} />;
    }

    return (
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>
              {title} ({followups.length})
            </span>
            <ButtonGroup>
              <Button appearance="link" onClick={this.modal} color="green">
                Create +
              </Button>
              <Button
                appearance="link"
                onClick={() => {
                  this.loadCustomerFollowups();
                }}
              >
                Refresh
              </Button>
            </ButtonGroup>
          </Stack>
        }
        bordered
      >
        <List size="lg" hover>
          {followups.map((item, index) => (
            <List.Item
              key={index}
              index={index}
              onClick={() => this.setItem(item, true)}
            >
              <Stack
                justifyContent="space-between"
                style={{ textAlign: "left" }}
              >
                <span>
                  <strong>
                    {item.mode} by USER#{item.created_by}
                  </strong>
                  <br />
                  <i>{moment(item.time).fromNow()}</i>
                  <br />
                  <p>Remarks: {item.remarks}</p>
                </span>
              </Stack>
              <CustomProgress progress={item.response} />
            </List.Item>
          ))}
        </List>

        <Modal open={this.state.modal} onClose={() => this.modal(false)}>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputPicker
              data={modes?.map((item) => ({
                label: item,
                value: item,
              }))}
              block
              value={item.mode}
              onChange={(value) => this.changeItem("mode", value)}
              disabled={item.id && this.props.sessionCheck.role !== "Admin"}
            />
            <br />
            <i>
              Remarks{" "}
              {item.remarks?.toLowerCase()?.includes("visit") ? (
                <Tag color="red">CVisit</Tag>
              ) : (
                ""
              )}
            </i>
            <InputAI
              disabled={item.id && this.props.sessionCheck.role !== "Admin"}
              as="textarea"
              rows={3}
              placeholder="Remarks"
              value={item.remarks}
              onChange={(value) => this.changeItem("remarks", value)}
            />
            <br />
            <strong>Response rate {item.response ?? 0}%</strong>
            <Slider
              progress
              value={item.response}
              onChange={(value) => {
                this.changeItem("response", value);
              }}
              disabled={item.id && this.props.sessionCheck.role !== "Admin"}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.save}
              appearance="primary"
              disabled={item.id && this.props.sessionCheck.role !== "Admin"}
            >
              {this.state.loading ? (
                <Loader size="xs" content="Saving" />
              ) : (
                "Save"
              )}
            </Button>
            <Button
              onClick={() => this.modal(false)}
              appearance="subtle"
              disabled={this.state.loading}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Panel>
    );
  }
}

export default CustomerFollowup;
