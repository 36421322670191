import React, { Component } from "react";
import {
  Button,
  CheckPicker,
  InputGroup,
  Input,
  Modal,
  Divider,
  Toggle,
  Drawer,
  DatePicker,
  Nav,
  Badge,
  Calendar,
  Tag,
  List,
  Stack,
  InputPicker,
} from "rsuite";
import {
  loadDeliveryData,
  loadProducts,
  loadRoutes,
  loadUsers,
  loadVisitData,
} from "../Loaders/GeneralLoder";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import FunnelIcon from "@rsuite/icons/Funnel";
import ListIcon from "@rsuite/icons/List";
import ExploreIcon from "@rsuite/icons/Explore";
import ReloadIcon from "@rsuite/icons/Reload";
import { MdOutlineGraphicEq } from "react-icons/md";
import { FaMapMarkedAlt } from "react-icons/fa";
import { TiMessageTyping } from "react-icons/ti";

import {
  InfoWindow,
  Marker,
  Circle,
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  Polygon,
  Polyline,
  DirectionsRenderer,
  MarkerClusterer,
  PolygonF,
  PolylineF,
  MarkerClustererF,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import Cookies from "js-cookie";
import constants, {
  alertError,
  alertInfoSilent,
  haversineDistance,
} from "../constants";
import CustomerView from "./sub-pages/mobile/customer-view";
import HomeLeadView from "./sub-pages/mobile/home-lead-view";
import moment from "moment";
import CustomLoader from "../Widgets/CustomLoader";
import LeadListItem from "../components/LeadListItem";
import {
  deepCopy,
  getFavCustomers,
  getProductListFromDelivery,
  truncatedString,
} from "../Helpers/Utilities";
import SalesQtyList from "../Modules/SalesQtyList";
import CustomerListItem from "../components/CustomerListItem";
import SaleDeliveryAssign from "../Modules/SaleDeliveryAssign";

const deliveryUserFilters = ["All", "Own", "User-Wise", "Un-Assigned"];

class Deliveries extends Component {
  state = {
    routes: [],
    deliveryStats: [],
    selectedRoutes: JSON.parse(Cookies.get("selectedRoutes") ?? "[]"),
    selectedProducts: [],
    products: [],
    loading: false,
    followMe: false,
    map: null,
    filterDeliveryDate: null,
    filterToggleShowAllSales: false,
    filterGoogleMapPlaces: false,
    filterToggleReturns: false,
    filterDeliveryUser: "All",
    filterDeliveryUserId: null,
    users: [],
    selectedCustomer: null,
    clickedCustomer: null,
    mode: "dash",
  };

  componentDidMount = () => {
    loadRoutes((routes) => this.setState({ routes }));
    if (this.state.selectedRoutes?.length > 0) {
      this.loadStats();
    }

    loadUsers((users) => this.setState({ users }));

    //Here the products are loaded and the the selectedProdcuts are set to all available products..
    loadProducts((products) =>
      this.setState({
        products,
      })
    );
  };

  componentDidUpdate = () => {
    //stick map position to current location..
    if (this.state.map && this.state.followMe) {
      this.state.map.setCenter(this.props.currentLocation);
    }
  };

  loadStats = () => {
    this.setState({ loading: true });
    fetch(constants.url + "delivery-stats", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify({ routes: this.state.selectedRoutes }),
    })
      .then((response) => response.json())
      .then((deliveryStats) => {
        this.setState({ deliveryStats, loading: false });
      })
      .catch((error) => {
        alertError("Unable to load stats.");
        console.error(error);
      });
  };

  loadData = () => {
    this.setState({ loading: true });

    if (this.state.filterDeliveryDate) {
      loadDeliveryData(
        this.state.selectedRoutes,
        this.state.filterDeliveryDate
          ? moment(this.state.filterDeliveryDate).format("YYYY-MM-DD") // Use Moment to format the date
          : null,
        (data) => {
          this.setState({ data, loading: false });
        }
      );
    }
  };

  filteredData = () => {
    var { selectedProducts, filterDeliveryUser, filterDeliveryUserId, users } =
      this.state;

    if (!this.state.data) {
      return null;
    }

    const data = deepCopy(this.state.data);

    // Filter by selected products
    if (selectedProducts && selectedProducts.length > 0) {
      data.delivery = data.delivery.filter((sale) => {
        // Check if every product in the sale is included in the selected products
        return sale.items.every((item) =>
          selectedProducts.includes(item.product_id)
        );
      });
    }

    //Display pnding deliveries only when filterToggleShowAllSales is false
    if (!this.state.filterToggleShowAllSales) {
      data.delivery = data.delivery.filter((sale) => sale.status == "delivery");
    }

    // FILTERS FOR DELIVERY USER..
    if (filterDeliveryUser === deliveryUserFilters[1]) {
      //OWN DELIVERIES ONLY..
      data.delivery = data.delivery.filter(
        (sale) => sale.delivery_by === this.props.sessionCheck?.user?.id
      );
    } else if (
      filterDeliveryUser === deliveryUserFilters[2] &&
      filterDeliveryUserId !== null
    ) {
      //USER WISE LSIT..
      data.delivery = data.delivery.filter(
        (sale) => sale.delivery_by === filterDeliveryUserId
      );
    } else if (filterDeliveryUser === deliveryUserFilters[3]) {
      //UN ASSIGNED DELIVERIES..
      data.delivery = data.delivery.filter((sale) => sale.delivery_by == null);
    }

    return data;
  };

  // THIS UNCTION IS TO PREVENT UNWANTED LOADING FROM THE BACKEND..
  tempUpdateData = (sale) => {
    const { data } = this.state;

    // Find the index of the sale in the delivery array
    const index = data.delivery.findIndex((s) => s.id === sale.id);

    // Update the sale if it exists
    if (index !== -1) {
      data.delivery[index] = sale;
      this.setState({ data });
    }
  };

  render() {
    var { routes, selectedRoutes, loading, followMe, selectedCustomer, mode } =
      this.state;

    const data = this.filteredData();
    const returnsForTheday = data?.delivery?.flatMap(
      (delivery) => delivery?.customer?.latest_sales_returns
    );
    return (
      <div>
        <CustomLoader full text="Loading deliveries.." loading={loading} />

        <Stack alignItems="center" justifyContent="space-between">
          <SalesQtyList sales={data?.delivery} label="DEL_QTY" />
          {this.state.filterDeliveryDate != null &&
          returnsForTheday?.length > 0 ? (
            <SalesQtyList
              returns={returnsForTheday}
              label={`RET_QTY (${returnsForTheday?.length})`}
            />
          ) : (
            ""
          )}
          <Button
            disabled={loading}
            onClick={() => {
              this.loadData();
              this.loadStats();
            }}
            size="xs"
            color="blue"
            appearance="primary"
          >
            &nbsp;&nbsp;
            <ReloadIcon />
            &nbsp; &nbsp;
          </Button>
          <Button
            disabled={loading}
            size="xs"
            color="blue"
            appearance="primary"
            onClick={() => this.setState({ filterModal: true })}
          >
            &nbsp; &nbsp;
            <FunnelIcon />
            &nbsp;&nbsp;
          </Button>
          <Button
            disabled={loading}
            onClick={() => this.setState({ followMe: !followMe })}
            appearance={followMe ? "primary" : "default"}
            color="green"
            size="xs"
          >
            <ExploreIcon />
            &nbsp; Stick
          </Button>
        </Stack>

        {/* Mode Nav */}
        <Nav
          appearance="subtle"
          justified
          defaultActiveKey="dash"
          activeKey={mode}
          onSelect={(mode) =>
            this.setState({
              mode,
              // setting filterDeliveryDate to null if the calander nav is clicked..
              filterDeliveryDate:
                mode == "dash" ? null : this.state.filterDeliveryDate,
            })
          }
        >
          {this.state.filterDeliveryDate != null ? (
            <>
              <Nav.Item eventKey="dash">&lt;&nbsp;Calendar</Nav.Item>
              <Nav.Item eventKey="list">
                List ({data?.delivery?.length ?? 0})
              </Nav.Item>
              <Nav.Item eventKey="map">Map</Nav.Item>
            </>
          ) : (
            ""
          )}
        </Nav>

        {/* Dashboard mode */}
        {mode === "dash" && (
          <div>
            <Calendar
              bordered
              defaultValue={new Date()} // Default date
              onSelect={(date) =>
                this.setState(
                  {
                    filterDeliveryDate: date,
                    mode: "list",
                  },
                  this.loadData
                )
              }
              // Disable all dates except the last 5 days from today
              disabledDate={(date) => {
                const today = new Date();
                const eightDaysAgo = new Date();
                const dayAfterTomorrow = new Date();

                eightDaysAgo.setDate(today.getDate() - 8); // 8 days before today
                dayAfterTomorrow.setDate(today.getDate() + 2); // Day after tomorrow

                // Disable dates outside the range for non-admins
                return (
                  this.props.sessionCheck?.role !== "Admin" &&
                  (date < eightDaysAgo || date > dayAfterTomorrow)
                );
              }}
              renderCell={(date) => {
                // Get stats for the current date from deliveryStats
                const dayStats = this.state.deliveryStats.find(
                  (stat) =>
                    new Date(stat.date).getDate() === date.getDate() &&
                    new Date(stat.date).getMonth() === date.getMonth() &&
                    new Date(stat.date).getFullYear() === date.getFullYear()
                );

                // Destructure or default to zero if no data found for the date
                const {
                  total_deliveries = 0,
                  completed_deliveries = 0,
                  cancelled_deliveries = 0,
                } = dayStats || {};

                return (
                  <div style={{ textAlign: "center", padding: "4px 0" }}>
                    {/* Total deliveries (Blue) */}
                    <div
                      style={{
                        display: "inline-block",
                        width: 20,
                        height: 20,
                        backgroundColor:
                          total_deliveries > 0 ? "#3498DB" : "lightgrey",
                        borderRadius: "4px 0px 0px 4px",
                        color: "white",
                        fontSize: "12px",
                        lineHeight: "20px",
                        margin: "0",
                      }}
                    >
                      {total_deliveries}
                    </div>

                    {/* Completed deliveries (Green) */}
                    <div
                      style={{
                        display: "inline-block",
                        width: 20,
                        height: 20,
                        backgroundColor:
                          completed_deliveries > 0 ? "#2ECC71" : "lightgrey",
                        borderRadius: "0",
                        color: "white",
                        fontSize: "12px",
                        lineHeight: "20px",
                      }}
                    >
                      {completed_deliveries}
                    </div>

                    {/* Cancelled deliveries (Red) */}
                    <div
                      style={{
                        display: "inline-block",
                        width: 20,
                        height: 20,
                        backgroundColor:
                          cancelled_deliveries > 0 ? "#E74C3C" : "lightgrey",
                        borderRadius: "0px 4px 4px 0px",
                        color: "white",
                        fontSize: "12px",
                        lineHeight: "20px",
                      }}
                    >
                      {cancelled_deliveries}
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}

        {/* Map Mode */}
        {mode == "map" && (
          <>
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "76vh",
              }}
              zoom={10}
              onLoad={(map) => {
                this.setState({ map });
                map.setCenter(
                  data?.delivery[0]
                    ? JSON.parse(data?.delivery[0]?.customer.geo_location)
                    : this.props.currentLocation
                );
              }}
              onUnmount={() => this.setState({ map: null })}
              mapTypeId="roadmap"
              options={{
                styles: !this.state.filterGoogleMapPlaces
                  ? [
                      {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }], // Hides all points of interest (places marker)
                      },
                    ]
                  : [], // Empty styles array means no styles are applied
              }}
            >
              {selectedRoutes.map((selected) =>
                routes.map(
                  (route) =>
                    route.id == selected && (
                      <PolygonF
                        options={{
                          paths: JSON.parse(route?.cord1).map((coord) => ({
                            lat: parseFloat(coord.lat),
                            lng: parseFloat(coord.lng),
                          })),
                          strokeColor: "#1abc9c",
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillOpacity: 0,
                        }}
                      />
                    )
                )
              )}

              {this.state.filterToggleReturns &&
                data?.returns?.map((ret, index) => (
                  <MarkerF
                    key={`RET${index}`}
                    position={JSON.parse(ret.customer.geo_location)}
                    icon={{
                      url: constants.marker_black,
                      scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                    }}
                    label={{
                      text: "R",
                      color: "white",
                      fontSize: "10",
                    }}
                    onClick={() => {
                      alertInfoSilent(
                        ret.customer.shop_name + " (View)",
                        3000,
                        () => this.setState({ selectedCustomer: ret.customer })
                      );
                      return false;
                    }}
                    onDblClick={() =>
                      (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                        JSON.parse(ret.customer.geo_location).lat
                      },${
                        JSON.parse(ret.customer.geo_location).lng
                      }&travelmode=motorcycle`)
                    }
                  />
                ))}

              {data?.delivery?.map((sale, index) => (
                <MarkerF
                  key={`D${index}`}
                  position={JSON.parse(sale.customer.geo_location)}
                  icon={{
                    url:
                      sale.customer.latest_sales_returns?.length > 0
                        ? constants.marker_pink
                        : constants.marker_red,
                    scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                  }}
                  label={{
                    text: truncatedString(sale.customer.shop_name),
                    color: "black",
                    fontSize: "8",
                  }}
                  onClick={() => {
                    setTimeout(() => {
                      if (this.state.clickedCustomer?.id !== sale.customer.id) {
                        this.setState({ clickedCustomer: sale.customer });
                      }
                    }, 100); // delay by 100ms
                  }}
                  onDblClick={() =>
                    (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                      JSON.parse(sale.customer.geo_location).lat
                    },${
                      JSON.parse(sale.customer.geo_location).lng
                    }&travelmode=motorcycle`)
                  }
                >
                  {/* Render InfoWindow only for the clickedCustomer */}
                  {this.state.clickedCustomer?.id === sale.customer.id && (
                    <InfoWindowF
                      position={JSON.parse(sale.customer.geo_location)} // Explicit position
                      onCloseClick={() =>
                        this.setState({ clickedCustomer: null })
                      }
                    >
                      <span style={{ padding: "4px" }}>
                        <strong>
                          {this.state.clickedCustomer.shop_name} (
                          {this.state.clickedCustomer.id})
                        </strong>
                        <br />
                        <Tag>
                          {parseFloat(
                            haversineDistance(
                              JSON.parse(sale.customer.geo_location),
                              this.props.currentLocation
                            ) / 1000
                          ).toFixed(2)}{" "}
                          KM
                        </Tag>
                        <br />
                        {data?.delivery
                          ?.filter((d) => d.customer.id === sale.customer.id)
                          ?.map((similarSale) => (
                            <span key={similarSale.id}>
                              <i style={{ fontSize: 2, color: "gray" }}>
                                {similarSale.notes}
                              </i>
                              {similarSale?.tracks
                                .filter((t) => t.type === "Notes Added")
                                .map((track) => (
                                  <div
                                    key={track.id}
                                    style={{
                                      padding: "4px",
                                      border: "1px dotted #ededed",
                                      marginTop: "2px",
                                    }}
                                  >
                                    <TiMessageTyping /> &nbsp; {track.remarks}
                                  </div>
                                ))}
                              <br />
                              {similarSale.items.map((item, idx) => (
                                <small key={idx}>
                                  {item.quantity} x {item.product.name}
                                  <br />
                                </small>
                              ))}
                              {this.props.sessionCheck.role === "Admin" ||
                              this.props.sessionCheck.roles.includes(
                                "SALE_DELIVERY_ASSIGN"
                              ) ? (
                                <SaleDeliveryAssign
                                  key={similarSale.id}
                                  sale={similarSale}
                                  users={this.state.users}
                                  onChange={(delivery_by) => {
                                    similarSale.delivery_by = delivery_by;
                                    this.tempUpdateData(similarSale);
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              <hr />
                            </span>
                          ))}
                        <Button
                          appearance="primary"
                          onClick={() =>
                            this.setState({
                              selectedCustomer: this.state.clickedCustomer,
                            })
                          }
                          size="xs"
                        >
                          View Customer
                        </Button>
                        <br />
                        <Button
                          appearance="primary"
                          color="green"
                          onClick={() =>
                            (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                              JSON.parse(sale.customer.geo_location).lat
                            },${
                              JSON.parse(sale.customer.geo_location).lng
                            }&travelmode=motorcycle`)
                          }
                          size="xs"
                        >
                          NAVIGATE
                        </Button>
                        <br />
                      </span>
                    </InfoWindowF>
                  )}
                </MarkerF>
              ))}

              <MarkerF
                position={this.props.currentLocation}
                label={{
                  text: "You",
                  fontSize: "10",
                }}
                icon={{
                  url: constants.marker_blue,
                  scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                }}
              />
            </GoogleMap>
          </>
        )}

        {/* List Mode */}
        {mode == "list" && (
          <div>
            {/* below logic check if the customer is duplicate, then simply rendering only one, actual element is not removed */}
            {(() => {
              const uniqueCustomers = new Set(); // Track unique customer IDs

              return data?.delivery
                ?.filter((sale) => {
                  if (uniqueCustomers.has(sale.customer.id)) {
                    return false; // Skip if customer ID already exists
                  }
                  uniqueCustomers.add(sale.customer.id); // Add new customer ID to the set
                  return true;
                })
                .map((sale, index) => (
                  <div key={`deliverylist-${index}`}>
                    <CustomerListItem
                      customer={sale.customer}
                      favs={getFavCustomers(() => {})}
                      onFavUpdate={(favs) =>
                        Cookies.set("favs", JSON.stringify(favs), {
                          expires: 365,
                        })
                      }
                      // Find all sales associated with this customer ID
                      sales={data?.delivery?.filter(
                        (d) => d.customer.id === sale.customer.id
                      )}
                      selectCustomer={() => {
                        this.setState({ selectedCustomer: sale.customer });
                      }}
                    />
                  </div>
                ));
            })()}
          </div>
        )}

        {/* Filter Modal */}
        <Modal
          open={this.state.filterModal}
          onClose={() => this.setState({ filterModal: false })}
        >
          <Modal.Header>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Divider>Routes</Divider>
            <CheckPicker
              virtualized
              size="sm"
              label="Routes"
              data={routes.map((route) => ({
                label: route.route_name,
                value: route.id,
              }))}
              loading={loading}
              value={selectedRoutes}
              onChange={(selectedRoutes) => {
                this.setState({ selectedRoutes });
                Cookies.set("selectedRoutes", JSON.stringify(selectedRoutes));
              }}
            />
            <Divider>Filter List</Divider>
            <i>Based on products</i>
            <CheckPicker
              virtualized
              size="sm"
              label="Routes"
              data={this.state.products.map((product) => ({
                label: product.name + ` (${product.category?.name})`,
                value: product.id,
              }))}
              loading={loading}
              value={this.state.selectedProducts}
              onChange={(selectedProducts) => {
                this.setState({ selectedProducts });
              }}
            />
            <br />
            {this.props.sessionCheck.role === "Admin" ||
            this.props.sessionCheck.roles.includes("SALE_DELIVERY_ASSIGN") ? (
              <span>
                <Divider>Delivery User Filter</Divider>
                <InputPicker
                  size="sm"
                  data={deliveryUserFilters.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  value={this.state.filterDeliveryUser}
                  onChange={(filterDeliveryUser) => {
                    this.setState({ filterDeliveryUser });
                  }}
                />
                <br />
                <br />
                {this.state.filterDeliveryUser === deliveryUserFilters[2] ? (
                  <InputPicker
                    size="sm"
                    data={this.state.users.map((user) => ({
                      label:
                        user.name +
                        ` (${
                          this.state.data?.delivery?.filter(
                            (d) => d.delivery_by == user.id
                          )?.length ?? 0
                        } deliveries)`,
                      value: user.id,
                    }))}
                    value={this.state.filterDeliveryUserId}
                    onChange={(filterDeliveryUserId) => {
                      this.setState({ filterDeliveryUserId });
                    }}
                  />
                ) : (
                  ""
                )}
                <br />
                <br />
              </span>
            ) : (
              ""
            )}
            <i>Delivery Date Filter</i>
            <DatePicker
              oneTap
              block
              editable={false}
              value={this.state.filterDeliveryDate}
              onChange={(filterDeliveryDate) => {
                this.setState({ filterDeliveryDate }, this.loadData);
              }}
              disabledDate={(date) => {
                const today = new Date();
                const eightDaysAgo = new Date();
                const dayAfterTomorrow = new Date();

                eightDaysAgo.setDate(today.getDate() - 8); // 8 days before today
                dayAfterTomorrow.setDate(today.getDate() + 2); // Day after tomorrow

                // Disable dates outside the range for non-admins
                return date < eightDaysAgo || date > dayAfterTomorrow;
              }}
            />
            <br />
            <Toggle
              size="xs"
              checked={this.state.filterToggleShowAllSales}
              checkedChildren="Only Pending Deliveries"
              unCheckedChildren="Show Total Sales"
              onChange={(filterToggleShowAllSales) => {
                this.setState({ filterToggleShowAllSales });
              }}
            />
            <Divider>Options</Divider>
            <Toggle
              size="xs"
              checked={this.state.filterGoogleMapPlaces}
              checkedChildren="Google Places"
              unCheckedChildren="Google Places"
              onChange={(filterGoogleMapPlaces) => {
                this.setState({ filterGoogleMapPlaces });
              }}
            />{" "}
            |
            <Toggle
              size="xs"
              checked={this.state.filterToggleReturns}
              checkedChildren="Hide Returns"
              unCheckedChildren="Show Returns"
              onChange={(filterToggleReturns) => {
                this.setState({ filterToggleReturns });
              }}
            />
          </Modal.Body>
        </Modal>

        {/* Lead & Customer View Drawer */}
        {selectedCustomer != null ? (
          <Drawer
            placement="bottom"
            size="full"
            open={selectedCustomer != null}
            onClose={() => this.setState({ selectedCustomer: null })}
          >
            <Drawer.Header>
              <Drawer.Title>
                View {selectedCustomer ? "Customer" : "Lead"}
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              {selectedCustomer ? (
                <CustomerView
                  data={selectedCustomer}
                  back={() => this.setState({ selectedCustomer: null })}
                  currentLocation={this.props.currentLocation}
                  sessionCheck={this.props.sessionCheck}
                />
              ) : (
                ""
              )}
            </Drawer.Body>
          </Drawer>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Deliveries;
