import Cookies from "js-cookie";
import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  DatePicker,
  DateRangePicker,
  Divider,
  InputPicker,
  Modal,
} from "rsuite";
import constants, { alertError, alertInfo } from "../constants";
import { loadUsers } from "../Loaders/GeneralLoder";
import CustomLoader from "../Widgets/CustomLoader";

class VisitDateChanger extends Component {
  state = {
    dates: [new Date(), new Date()],
    toDate: new Date(),
    route: null,
    user: null,
    users: [],
    modal: false,
    loading: false,
  };

  loading = (loading = true) => this.setState({ loading });
  componentDidMount = () => {
    if (this.props.sessionCheck.role === "Admin") {
      loadUsers((users) => this.setState({ users }));
    }
  };

  apply = (checkOnly, moveOnly = 0) => {
    var { dates, route, user } = this.state;
    this.loading();
    const startDate = moment(dates[0]).format("YYYY-MM-DD");
    const endDate = moment(dates[1]).format("YYYY-MM-DD");
    const toDate = moment(this.state.toDate).format("YYYY-MM-DD");

    // Construct the request body with the parameters
    const requestBody = {
      startDate,
      endDate,
      toDate,
      checkOnly,
      route,
      user,
      moveOnly,
    };

    fetch(constants.url + "visits-date-change", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
      body: JSON.stringify(requestBody), // Send the parameters in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.loading(false);
        if (!checkOnly) {
          alertInfo("Dates changed");
        } else {
          alert(`Total ${data?.total_events} found in the selected date range`);
        }
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
        alertError(
          "Failed to change dates.. check console window and update with IT Admin"
        );
      });
  };
  render() {
    if (this.props.sessionCheck.role != "Admin") {
      return "";
    }

    return (
      <span>
        <CustomLoader full text="Loading.." loading={this.state.loading} />
        <Button
          appearance="primary"
          color="yellow"
          onClick={() => this.setState({ modal: true })}
          style={{ margin: 10 }}
        >
          Visits - Bulk Date Changer
        </Button>
        <Modal
          open={this.state.modal}
          onClose={() => this.setState({ modal: false })}
        >
          <Modal.Header>
            <Modal.Title>Visits - Bulk Date Changer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Divider>Select Route ({this.props.routes.length})</Divider>
            <InputPicker
              size="sm"
              // searchable={false}
              data={this.props.routes.map((route) => ({
                label: route.route_name,
                value: route.id,
              }))}
              value={this.state.route}
              onChange={(route) => this.setState({ route })}
            />

            <span>
              <Divider>Select User ({this.state.users.length})</Divider>
              <InputPicker
                size="sm"
                // searchable={false}
                data={this.state.users.map((user) => ({
                  label: user.name,
                  value: user.id,
                }))}
                value={this.state.user}
                onChange={(user) => this.setState({ user })}
              />
            </span>

            {this.state.route || this.state.user ? (
              <span>
                <Divider>Select Date Range</Divider>
                <DateRangePicker
                  placeholder="Select Dates"
                  onChange={(dates) => this.setState({ dates })}
                  placement="auto"
                  value={this.state.dates}
                  size="sm"
                  showOneCalendar
                  block
                />
                <Divider>Select Date To Move</Divider>
                <DatePicker
                  placeholder="Select date to move"
                  onChange={(toDate) => this.setState({ toDate })}
                  placement="auto"
                  value={this.state.toDate}
                  size="sm"
                  block
                />
              </span>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.apply(true)} appearance="primary">
              Check
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              onClick={() => this.apply(false)}
              appearance="primary"
              color="green"
            >
              Move All
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              onClick={() => {
                var count = window.prompt("Enter number of visits to be moved");
                if (count && count > 0) {
                  this.apply(false, count);
                } else {
                  alertError("Invalid move number");
                }
              }}
              appearance="ghost"
              color="yellow"
            >
              Move Only
            </Button>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

export default VisitDateChanger;
