import Cookies from "js-cookie";
import React, { Component } from "react";
import { Loader, Modal } from "rsuite";
import constants, { getUserImage, play } from "../constants";
import { IoMdVolumeOff } from "react-icons/io";
import { IoVolumeHigh } from "react-icons/io5";
import moment from "moment";

class LeadingCRE extends Component {
  state = {
    data: [],
    loading: false,
    modal: false,
    totalCustomers: 0,
    volume: JSON.parse(Cookies.get("allow_progress_sound", "true") ?? "true"),
    started: false,
  };

  componentDidMount = () => {
    this.loadData();
  };

  loading = (loading = true) => this.setState({ loading });

  loadData = () => {
    this.loading();
    fetch(constants.url + "leadingCre", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const newTotalCustomers = data.report.reduce(
          (total, item) => total + item.customer_count,
          0
        );

        // Compare with the previous total customers
        if (
          newTotalCustomers > this.state.totalCustomers &&
          this.state.volume
        ) {
          play(5);
        }

        this.setState({
          data: data.report,
          lastLoad: new Date(),
          totalCustomers: newTotalCustomers, // Update totalCustomers in state
        });

        if (!this.state.started) {
          this.setState({ started: true }, () =>
            setTimeout(this.loadData, 5 * 60 * 1000)
          );
        }

        this.loading(false);
      })
      .catch((error) => {
        console.error(error);
        setTimeout(this.loadData, 90 * 1000);
        this.loading(false);
      });
  };

  render() {
    const { data, loading, modal } = this.state;

    if (loading) {
      return <Loader size="xs" />;
    }

    if (data.length === 0) {
      return <span></span>;
    }

    // Sort the data in descending order based on customer_count
    const sortedData = [...data].sort(
      (a, b) => b.customer_count - a.customer_count
    );

    // Get the top performer
    const topPerformer = sortedData[0];

    return (
      <span>
        <div
          className="leading-cre-widget"
          onClick={() => {
            this.setState({ modal: true });
            if (moment().diff(moment(this.state?.lastLoad), "minutes") > 2) {
              this.loadData();
            }
          }}
        >
          <div className="circle">
            <img
              src={getUserImage(topPerformer?.user)}
              alt="Top Performer"
              className="user-image"
            />
            <div className="customer-count">{topPerformer.customer_count}</div>
          </div>
        </div>

        <Modal open={modal} onClose={() => this.setState({ modal: false })}>
          <Modal.Header>
            <Modal.Title
              onClick={() =>
                this.setState({ volume: !this.state.volume }, () =>
                  Cookies.set(
                    "allow_progress_sound",
                    JSON.stringify(this.state.volume)
                  )
                )
              }
            >
              {this.state.volume ? <IoVolumeHigh /> : <IoMdVolumeOff />} &nbsp;
              Top Performers
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="topper-list">
              <ul>
                {sortedData.map((item, index) => (
                  <li key={item.user.id} className="topper-item">
                    <img
                      src={getUserImage(item.user)}
                      alt={item.user.name}
                      className="topper-image"
                    />
                    <div className="topper-info">
                      <span className="rank">{index + 1}.</span>
                      <span className="name">{item.user.name}</span> -
                      <span className="count">
                        {item.customer_count} Customers
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Modal.Body>
        </Modal>
      </span>
    );
  }
}

export default LeadingCRE;
