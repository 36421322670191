import Cookies from "js-cookie";
import moment from "moment";
import React, { Component } from "react";
import {
  AutoComplete,
  Button,
  DatePicker,
  Drawer,
  Input,
  InputPicker,
  List,
  SelectPicker,
  Slider,
  Stack,
  Tag,
  Toggle,
} from "rsuite";
import constants, { alertError, alertInfo, sqlDateTime } from "../constants";
import PhoneFillIcon from "@rsuite/icons/PhoneFill";
import MessageIcon from "@rsuite/icons/Message";
import PeoplesIcon from "@rsuite/icons/Peoples";
import LeadFollowupForm from "../components/LeadFollowupForm";
import Revisions from "../Modules/Revisions";

class HomeLeadFollowup extends Component {
  state = {
    loading: false,
    followups: [],
    lastLoadId: null,
    followupDrawer: false,
    selected: null,
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  changeValue = (where, what) => {
    var { selected } = this.state;
    selected[where] = what;
    this.setState({ selected });
  };

  followupDrawer = (followupDrawer = true, selected = null) => {
    if (!selected) {
      selected = {
        remark: "",
        kind: "Phone call",
        event: 0,
        event_date: null,
        event_done: 0,
        event_done_at: null,
        event_remark: "",
      };
    }

    this.setState({ followupDrawer, selected });
  };

  componentDidMount = () => {
    this.load();
  };

  componentDidUpdate = () => {
    if (this.state.lastLoadId !== this.props?.lead?.id) {
      this.setState({ lastLoadId: this.props.lead.id }, this.load);
    }
  };

  load = () => {
    this.setLoading();
    fetch(constants.url + "lead_followups/" + this.props?.lead?.id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then(({ followups }) => {
        this.setState({ followups, lastLoadId: this.props?.lead?.id });
        this.setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
      });
  };

  save = () => {
    this.setLoading();
    var { selected } = this.state;
    var { lead } = this.props;

    selected.lead_id = lead.id;
    selected.event_date = sqlDateTime(selected.event_date);
    selected.event_done_at = sqlDateTime(selected.event_done_at);

    fetch(
      constants.url + "lead_followup" + (selected.id ? `/${selected.id}` : ""),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(selected),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.load();
        this.setLoading(false);
        alertInfo(`${selected.id ? "Saved" : "Created"} Followup`);
        this.followupDrawer(false);

        //UPDATE LAST FOLLOWUP..
        if (lead.last_followup.id == selected.id) {
          lead.last_followup = data?.followup;
        }

        this.props.refresh();
        //
      })
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
        alertError(
          `Unable to ${selected.id ? "Save" : "Create"} Followup, try again`
        );
        this.followupDrawer(false);
      });
  };
  render() {
    var { lead } = this.props;
    var { selected, loading } = this.state;
    return (
      <div>
        <br />
        <Button
          block
          appearance="primary"
          onClick={this.followupDrawer}
          disabled={loading}
        >
          Create Followup / Event
        </Button>
        <br />
        <List hover>
          {this.state.followups.length == 0 && (
            <strong>No Follow Ups Yet</strong>
          )}
          {this.state.followups.map((followup) => {
            return (
              <List.Item
                onClick={() => this.followupDrawer(true, followup)}
                style={{ textAlign: "left" }}
              >
                {followup.kind == "Phone call" ? (
                  <PhoneFillIcon />
                ) : followup.kind == "Direct Visit" ? (
                  <PeoplesIcon />
                ) : (
                  <MessageIcon />
                )}{" "}
                &nbsp;
                {followup.remark} <br />
                <i>
                  {moment(followup.created_at).fromNow()} by{" "}
                  {followup.created_by_user.name}
                </i>
                {followup.event ? (
                  <>
                    <br />
                    <Tag color={followup.event_done ? "blue" : "orange"}>
                      Event:{" "}
                      {moment(followup.event_date).format("MMMM DD, YYYY")}
                    </Tag>
                    <Tag>{followup.event_type}</Tag>
                  </>
                ) : (
                  ""
                )}
              </List.Item>
            );
          })}
        </List>

        <Drawer
          open={this.state.followupDrawer}
          onClose={() => this.followupDrawer(false)}
          placement="bottom"
          size="full"
        >
          <Drawer.Header>
            <Drawer.Title>{lead.shop_name} Followup</Drawer.Title>
            <Drawer.Actions>
              <Button
                onClick={this.save}
                appearance="primary"
                color="green"
                disabled={loading}
              >
                Save
              </Button>
            </Drawer.Actions>
          </Drawer.Header>

          <Drawer.Body>
            {selected != null && (
              <>
                {this.props.sessionCheck?.role == "Admin" && (
                  <Revisions leadFollowupId={selected.id} original={selected} />
                )}

                <br />
                <LeadFollowupForm
                  selected={selected}
                  changeValue={this.changeValue}
                  lead={lead}
                  sessionCheck={this.props.sessionCheck}
                />
              </>
            )}
          </Drawer.Body>
        </Drawer>
      </div>
    );
  }
}

export default HomeLeadFollowup;
