import Cookies from "js-cookie";
import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Input,
  List,
  Loader,
  SelectPicker,
  Stack,
} from "rsuite";
import constants from "../../constants";
import { loadUsers } from "../../Loaders/GeneralLoder";

class SuperAccounts extends Component {
  state = {
    drawer: false,
    users: [],
    accounts: [],
    selectedUser: null,
    loading: false,
    loadText: "",
    searchTerm: "",
  };

  loading = (loading = true, loadText = "") =>
    this.setState({ loading, loadText });

  componentDidMount = () => {
    this.loading(true, "Loading..");
    fetch(constants.url + "super-accounts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("u-token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ accounts: data.accounts, users: data.users });
        this.loading(false);
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
      });
  };

  addUserAccount = (account) => {
    this.loading(true, "Adding.. " + account.name);

    fetch(
      constants.url +
        `super-accounts/add-user-account/${this.state.selectedUser}/${account.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.componentDidMount();
        this.loading(false);
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
      });
  };

  removeUserAccount = (userAccount) => {
    this.loading(true, "Removing..");

    fetch(
      constants.url + `super-accounts/remove-user-account/${userAccount.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.componentDidMount();
        this.loading(false);
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
      });
  };

  updateUserAccount = (userAccount, where, what) => {
    userAccount[where] = what;
    this.loading(true, "Updating..");
    fetch(
      constants.url + `super-accounts/update-user-account/${userAccount.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("u-token")}`,
        },
        body: JSON.stringify(userAccount),
      }
    )
      .then((response) => response.json())
      .then((resData) => {
        this.loading(false);
        this.componentDidMount();
      })
      .catch((error) => {
        console.error(error);
        this.loading(false);
      });
  };
  render() {
    if (!this.props.sessionCheck.roles.includes("ACCOUNTS_SUPER")) {
      return "";
    }

    const selectedUserObject = this.state.users.find(
      (u) => u.id == this.state.selectedUser
    );
    return (
      <>
        <Button
          appearance="primary"
          color="yellow"
          onClick={() => this.setState({ drawer: true })}
          size="sm"
          style={{ marginLeft: "10px" }}
        >
          Super
        </Button>
        <Drawer
          size="full"
          placement="right"
          open={this.state.drawer}
          onClose={() => this.setState({ drawer: false })}
        >
          <Drawer.Header>
            <Drawer.Title>
              Super Accounts &nbsp;{" "}
              <Input
                style={{ width: 200 }}
                placeholder="Search accounts"
                size="sm"
                value={this.state.searchTerm}
                onChange={(searchTerm) => this.setState({ searchTerm })}
              />
            </Drawer.Title>
            <Drawer.Actions>
              {this.state.loading ? (
                <Loader content={this.state.loadText} size="sm" />
              ) : (
                ""
              )}
              &nbsp; &nbsp; &nbsp;
              <Button
                onClick={this.componentDidMount}
                disabled={this.state.loading}
              >
                Refresh
              </Button>
              {/*<Button onClick={() => setOpen(false)} appearance="primary">
                    Confirm
                    </Button> */}
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <Divider>Select User</Divider>
            <SelectPicker
              data={this.state?.users?.map((user) => ({
                label: user.name,
                value: user.id,
              }))}
              value={this.state.selectedUser}
              onChange={(selectedUser) => this.setState({ selectedUser })}
            />
            <Divider />
            <div
              className={`account-list-container ${
                selectedUserObject ? "" : "hidden"
              }`}
            >
              {selectedUserObject && (
                <div className="account-list">
                  {this.state.accounts
                    .filter((ac) =>
                      this.state.searchTerm.length > 0
                        ? ac.name.toLowerCase().includes(this.state.searchTerm)
                        : true
                    )
                    .map((account) => {
                      const userAccount = selectedUserObject.accounts?.find(
                        (ua) => ua.acc_id === account.id
                      );

                      return (
                        <div key={account.id} className="account-item">
                          <div className="account-header">
                            <strong>{account.name}</strong>
                            {userAccount ? (
                              <div className="account-actions">
                                <label>
                                  <input
                                    type="checkbox"
                                    disabled={this.state.loading}
                                    checked={userAccount.in}
                                    onChange={(e) =>
                                      this.updateUserAccount(
                                        userAccount,
                                        "in",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  In
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    disabled={this.state.loading}
                                    checked={userAccount.out}
                                    onChange={(e) =>
                                      this.updateUserAccount(
                                        userAccount,
                                        "out",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  Out
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    disabled={this.state.loading}
                                    checked={userAccount.view_all}
                                    onChange={(e) =>
                                      this.updateUserAccount(
                                        userAccount,
                                        "view_all",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  View All
                                </label>
                                <button
                                  className="btn btn-remove"
                                  onClick={() =>
                                    this.removeUserAccount(userAccount)
                                  }
                                  disabled={this.state.loading}
                                >
                                  Remove Account
                                </button>
                              </div>
                            ) : (
                              <button
                                className="btn btn-add"
                                onClick={() => this.addUserAccount(account)}
                                disabled={this.state.loading}
                              >
                                Add Account
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default SuperAccounts;
