import React, { Component } from "react";
import { Modal, Input, Button } from "rsuite";
import { GiRobotHelmet } from "react-icons/gi";
import { IoSend } from "react-icons/io5";

import "./assistant-styles.css";
import { generateText } from "./AiHelper";

class StackAssistant extends Component {
  state = {
    modal: false,
    inputText: "",
    chats: [],
    loading: false,
  };

  loading = (loading = true) => this.setState({ loading });

  send = () => {
    var { inputText, chats } = this.state;
    if (inputText?.length < 2) {
      return;
    }
    this.loading();
    chats.push(inputText);
    this.setState({ chats });
    // Refactored prompt text for clarity
    const userName = this.props.sessionCheck?.user?.name || "User";
    const userRole =
      this.props.sessionCheck?.active_employment?.employment_type?.name ||
      "Employee";

    const assistantPrompt = `You are StackAI Assistant, developed by Nisam Nirar as part of Stack Enterprises. You are a chat assistant designed to help employees of SalesMart. My name is ${userName}, and my role at SalesMart is ${userRole}. 

We are having a conversation where I will ask you questions or send messages, and you will respond accordingly. Here is a log of our chat so far, presented as a string array where the first message is mine, the second is yours, and so on: ${JSON.stringify(
      chats
    )}.

Please carefully review the last message or question I sent and respond to it. Provide only the reply—do not repeat the previous messages or add unnecessary context.`;

    this.loading(true); // Set loading state before generating text

    generateText(assistantPrompt, (response) => {
      const reply =
        response?.data?.candidates?.[0]?.content?.parts?.[0]?.text ||
        "I'm sorry, I couldn't generate a response.";

      if (reply) {
        chats.push(reply); // Add the AI's reply to the chat history
        this.setState({ chats, inputText: "" }); // Clear inputText for the user
      }

      this.loading(false); // Reset loading state
    });
  };
  render() {
    return (
      <>
        <Button
          appearance="primary"
          color="green"
          block
          style={{ margin: 5 }}
          onClick={() => this.setState({ modal: true })}
        >
          <GiRobotHelmet /> Stack AI
        </Button>
        <Modal
          open={this.state.modal}
          onClose={() => this.setState({ modal: false })}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              <GiRobotHelmet /> Stack AI - Assistant
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="chat-body">
            {this.state.chats.map((chat, index) => (
              <div
                key={index}
                className={`chat-message ${
                  index % 2 === 0 ? "chat-me" : "chat-ai"
                }`}
              >
                <span className="chat-label">
                  {index % 2 === 0 ? "ME" : "StackAI"}
                </span>
                <p className="chat-text">{chat}</p>
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer className="chat-footer">
            <Input
              value={this.state.inputText}
              onChange={(value) => this.setState({ inputText: value })}
              className="chat-input"
              placeholder="Type a message..."
              disabled={this.state.loading}
            />
            <Button
              onClick={this.send}
              appearance="primary"
              className="send-button"
              disabled={this.state.loading}
            >
              <IoSend />
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default StackAssistant;
